import React from "react";

import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import * as querydata from "../services/dataServices";
import { useQuery } from "react-query";
import Image from "../components/Image";
import { stripHtml } from "../components/OfferSlider";
function OffersTabItem({
  offerId,
  offerImage,
  offerHeadingOne,
  offerSmallHeading,
  tabPara,
  offerHotelLocation,
  offerLink,
  bookLink,
  hotelName,
  hotelLocation,
  brandtabs,
  tabResorts,
  discountText,

  offerImageAltTag,

  slug,
  altlnk,
  slugOfOffers

}) {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);



  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);


    // if (lastSpaceIndex !== -1) {
    //   return truncatedText.slice(0, lastSpaceIndex);
    // }

    // If there is no space in the truncated part, just return the original truncated text
    return truncatedText;
  }
  function truncatepara(text, maxLength) {
    const strippedText=stripHtml(text)
    if (strippedText.length <= maxLength) {
      return strippedText;
    }

    const truncatedText = strippedText.slice(0, maxLength);

    // If there is no space in the truncated part, just return the original truncated tex
    return truncatedText;
  }
  const getWordCountValue = useQuery(
    ["AspectCount", "offer_word_count_in_card"],
    () => querydata.getAspectRatio("offer_word_count_in_card")
  );
  const wordCount = getWordCountValue && getWordCountValue.data && getWordCountValue.data.data.setting_value;

  return (
    <>
      <div className="tab-top-header position-relative">
        <div className="container">
          <div className="row position-relative">
            <div className="col-md-9">
              <motion.div
                key={Math.random()}
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="offer-img"
              >
                {/* <img
                  className="w-100"
                  src={offerImage}
                  alt={offerImageAltTag}
                  title=""
                /> */}
                <Image
                  className="w-100"
                  src={offerImage}
                  alt={offerImageAltTag}
                  title=""
                />
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="offer-info-card right-feature-text-col position-absolute top-0 bottom-0 m-auto d-flex align-items-center"
            >
              <div className="hotel-heading-wrap text-center right-feature-wrap bg-white">
                <span className="text-uppercase">
                  {offerHotelLocation} | Maldives
                </span>
                <h3 className="georgia heading-space mt-2">
                  {/* {offerHeadingOne},{"cv"} */}
                  {offerHeadingOne.length > wordCount ? (
                    <>

                      {parse(truncateAtLastWord(offerHeadingOne, wordCount))}

                      {" ...."}
                    </>
                  ) : (
                    parse(offerHeadingOne)

                  )}
                </h3>
                <span>
                  {/* {parseInt(offerSmallHeading)}% OFF */}
                  {discountText}
                </span>
                <p className="">
                  {tabPara.length > 100 ? (
                    <>

                      {parse(truncatepara(tabPara, 100))}

                      {" ...."}
                    </>
                  ) : (
                    truncatepara(tabPara)

                  )}
                  {/* {offerLink &&
                    offerLink.map((downloadLink) => ( */}

                  &nbsp;
                  <Link
                    // key={downloadLink.id}
                    className="text-decoration-underline view-all"
                    title=""
                    to={`/${slugOfOffers}/${slug}`}
                  >

                    {'Learn More'}{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="ms-1 icon-font"
                    />
                  </Link>
                  {/* ))} */}
                  {/* <Link to='/kanifushi-book-direct' className='text-decoration-underline view-all' title='Course Link'>Learn More <FontAwesomeIcon icon={faArrowRight} className='ms-1 icon-font' /></Link> */}
                </p>
                <div className="offer-book-now">
                  {/* {bookLink &&
                    bookLink.map((booknowLink) => ( */}
                  <Link
                    // key={booknowLink.id}
                    className="btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary book_now_offers_ga4"
                    title="Click here book now"
                    to={bookLink}
                    target="_blank"
                  >
                    {'BOOK NOW'}{" "}
                  </Link>
                  {/* ))} */}
                </div>
                {/* <Button className='btn primary theme-btn fw-medium text-white text-uppercase border-theme' onClick={handleShow}>Book Now</Button> 
                            <Modals show={show} handleClose={handleClose}/> */}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OffersTabItem;
