import React,{useContext,useState} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ExperienceAwaitSection from "../components/ExperienceAwaitSection";

import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { getPageData,getPageData1 } from "../services/dataServices";
import Helmet from "../components/Helmet";
import { MyContext } from "../Contex/LoadingContex"
import Loader from "../components/Loader";
import BannerImage from "../components/BannerImage";
import Image from "../components/Image";
function SustainabilityPage({isPreview, userId, sectionId}) {

  const pageCode = "SUSTAINABILITY";

  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
     
  const {isLoading,isSuccess}=pagesSectionData1
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);


  const pagesSectionData = useQuery(["pagesSectionData", pageCode,isPreview, userId, sectionId], () =>
    getPageData(pageCode,isPreview, userId, sectionId)
  );

 
  const managesustainbiltySection =
    pagesSectionData?.data?.data[0].section_details.filter(
      (item) => item.section_code === "MANAGESUSTAINABILITY"
    );


  const pageBanner = pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGESUSTAINABILITY" && item.field_label_code === "PAGEBANNER"
)[0]?.psdd_data ?? "";
  // managesustainbiltySection?.[0].section_elements.find(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )?.data;
 
  const pageBannerAltTag = pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGESUSTAINABILITY" && item.field_label_code === "PAGEBANNER"
)[0]?.img_alt_tag ?? "";
  // managesustainbiltySection?.[0].section_elements.find(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )?.img_alt_tag;
  const welcomeToAtmosphere =
    managesustainbiltySection?.[0]?.subSectionData.filter(
      (item) => item.sub_section_title_code === "WELCOMETOATMOSPHERE"
    );
  //data for main section

  const mainSectionIcon = welcomeToAtmosphere?.[0].elements.filter(
    (item) => item.field_title_code === "CHANGEICON"
  )[0].data;

  const mainSectionSubtitle = welcomeToAtmosphere?.[0].elements.filter(
    (item) => item.field_title_code === "SUBTITLE"
  )[0].data;
  const mainSectionTitle = welcomeToAtmosphere?.[0].elements.filter(
    (item) => item.field_title_code === "TITLE"
  )[0].data;
  const mainSectionDescription = welcomeToAtmosphere?.[0].elements.filter(
    (item) => item.field_title_code === "DESCRIPTION"
  )[0].data;
  const sustainabilityPdfButton=welcomeToAtmosphere?.[0].elements.filter(
    (item) => item.field_title_code === "SUSTAINABILITYMANAGEMENTBUTTON"
  )[0].data;
  const environmentButton=welcomeToAtmosphere?.[0].elements.filter(
    (item) => item.field_title_code === "ENVIRONMENTALPOLICYBUTTON"
  )[0].data;
  
  let keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.data[0]?.slug;
  let meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  let meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  
  return (
    <>
    <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100">
            {/* <img
              className="w-100 h-100"
              src={pageBanner}
              title=""
              onLoad={handleImageLoad}
              onError={handleError}
              alt={pageBannerAltTag}
            /> */}
             <BannerImage
              className="w-100 h-100"
              src={pageBanner}
              title=""
              alt={pageBannerAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Sustainability
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Sustainability</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <ExperienceAwaitSection
        image={mainSectionIcon}
        para={mainSectionDescription}
        showLearnMore={false}
        heading={mainSectionSubtitle}
        title={mainSectionTitle}
        visitLinks={true}
        sustainabilityPdfButton={sustainabilityPdfButton}
        environmentButton={environmentButton}
      />
      {/* <ExperienceAwait sustainheading={sustainheadingOne} heading={parse(headingOne)} image={starPerformanceImg} para={para} showLearnMore={false} visitLinks={true} /> */}

      <section>
        <div className="our-values sustainability-cards">
          <div className="container">
            {pagesSectionData &&
              pagesSectionData.data &&
              pagesSectionData.data.data.map((sectionData) => {
                return (
                  sectionData &&
                  sectionData.section_details

                    .filter(
                      (item) => item.section_code === "MANAGESUSTAINABILITY"
                    )[0]
                    .subSectionData.filter(
                      (item) =>
                        item.sub_section_title_code === "GREENGLOBECERTIFIED" ||
                        item.sub_section_title_code === "CARBONFOOTPRINTS"
                    )
                    .map((subData) => {
                      //   let sub_section_title = subData.sub_section_title;
                      let sub_section_title = subData.elements.find(
                        (item) => item.field_title_code === "HEADING"
                      )?.data;

                      return (
                        <>
                          <div className="row sustainrow position-relative">
                            <div className="col-md-7">
                              <motion.div
                                initial={{ opacity: 0, x: -100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                className="feature-left-image"
                              >
                                {/* <img
                                  className="w-100"
                                  src={
                                    subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.data ??
                                      null)
                                  }
                                  alt={subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.img_alt_tag ??
                                      null)}
                                  title=""
                                /> */}
                                <Image
                                  className="w-100"
                                  src={
                                    subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.data ??
                                      null)
                                  }
                                  alt={subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.img_alt_tag ??
                                      null)}
                                  title=""
                                />
                              </motion.div>
                            </div>
                            <motion.div
                              initial={{ opacity: 0, x: 100 }}
                              whileInView={{ opacity: 1, x: 0 }}
                              transition={{ duration: 1 }}
                              className="right-feature-text-col position-absolute bottom-0 end-0 top-0 d-flex align-items-center"
                            >
                              <div className="right-feature-wrap bg-white position-relative">
                                <div className="top-head-text">
                                  <h4 className="georgia text-uppercase">
                                    {sub_section_title}
                                  </h4>
                                </div>
                                <div className="feature-text-para position-relative">
                                  <p>
                                    {" "}
                                    {subData &&
                                      subData.elements.filter(
                                        (items) =>
                                          items.field_title_code ===
                                          "DESCRIPTION"
                                      )[0]?.data}
                                  </p>
                                  <div className="learnmore mt-xl-5 mt-4 d-none">
                                    <Link
                                      to="/coming-soon"
                                      className="text-decoration-none text-uppercase"
                                    >
                                      Learn More{" "}
                                      <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className="ms-2"
                                      />
                                    </Link>
                                  </div>
                                </div>
                                {subData &&
                                  subData?.elements?.filter(
                                    (items) =>
                                      items?.field_title_code ===
                                      "GREENGLOBECERTIFIEDLOGO"
                                  )[0] && (
                                    <div className="green-globe-icon position-absolute">
                                      {/* <img
                                        src={
                                          subData &&
                                          (subData.elements.filter(
                                            (items) =>
                                              items.field_title_code ===
                                              "GREENGLOBECERTIFIEDLOGO"
                                          )[0]?.data ??
                                            null)
                                        }
                                        alt={                                          subData &&
                                          (subData.elements.filter(
                                            (items) =>
                                              items.field_title_code ===
                                              "GREENGLOBECERTIFIEDLOGO"
                                          )[0]?.img_alt_tag ??
                                            null)}
                                        title=""
                                      /> */}
                                      <Image
                                        src={
                                          subData &&
                                          (subData.elements.filter(
                                            (items) =>
                                              items.field_title_code ===
                                              "GREENGLOBECERTIFIEDLOGO"
                                          )[0]?.data ??
                                            null)
                                        }
                                        alt={                                          subData &&
                                          (subData.elements.filter(
                                            (items) =>
                                              items.field_title_code ===
                                              "GREENGLOBECERTIFIEDLOGO"
                                          )[0]?.img_alt_tag ??
                                            null)}
                                        title=""
                                      />
                                    </div>
                                  )}
                              </div>
                            </motion.div>
                          </div>
                        </>
                      );
                    })
                );
              })}
            {/* <div className='row position-relative section-padding'>
                            <div className='col-md-7 offset-md-5'>
                                <motion.div
                                    initial={{ opacity: 0, x: 100 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 1 }}
                                    className='feature-left-image'>
                                    <img className='w-100' src={carbonFootprint} alt='Purpose' title='Pupropse' />
                                </motion.div>
                            </div>
                            <motion.div
                                initial={{ opacity: 0, x: -100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                className='right-feature-text-col position-absolute bottom-0 start-0 top-0 d-flex align-items-center'>
                                <div className='right-feature-wrap bg-white'>
                                    <div className='top-head-text'>
                                        <h4 className='georgia text-uppercase'>CARBON FOOTPRINT</h4>
                                    </div>
                                    <div className='feature-text-para position-relative'>
                                        <p>We have solar panels strategically installed on the rooftops at each property. Harnessing a comprehensive solar power infrastructure, we can effectively mitigate carbon emissions by approximately 2000 tons annually.</p>
                                        <div className='learnmore mt-xl-5 mt-4 d-none'>
                                            <Link to='/coming-soon' className='text-decoration-none text-uppercase'>Learn More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div> */}
          </div>
        </div>
      </section>


      {pagesSectionData &&
        pagesSectionData.data &&
        pagesSectionData.data.data.map((sectionData) => {
          return (
            sectionData &&
            sectionData.section_details

              .filter((item) => item.section_code === "MANAGESUSTAINABILITY")[0]
              .subSectionData.filter(
                (item) =>
                  item.sub_section_title_code === "SUSTAINABLEHOSPITALITY"
              )
              .map((subData) => {

                let sub_section_title = subData.elements.find(
                  (item) => item.field_title_code === "HEADING"
                )?.data;
                // let sub_section_title = subData.sub_section_title;
                return (
                  <>
                    <section>
                      <div className="environmental-tourism py-4 py-lg-5">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <motion.div
                                initial={{ opacity: 0, x: 100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                className="environmental-tourism-card ms-auto"
                              >
                                <h3 className="georgia text-uppercase">
                                  {sub_section_title}
                                </h3>
                                <p className="mt-4">
                                  {subData &&
                                    subData.elements.filter(
                                      (items) =>
                                        items.field_title_code === "DESCRIPTION"
                                    )[0]?.data}
                                </p>
                                <div className="environmental-action mt-xl-5 mt-4 d-none">
                                  <Link
                                    to="/coming-soon"
                                    className="btn btn-primary theme-btn fw-medium text-white text-uppercase border-theme"
                                  >
                                    learn more
                                  </Link>
                                </div>
                              </motion.div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                );
              })
          );
        })}
      <section>
        <div className="our-values sustainability-cards section-padding pb-0">
          <div className="container">
            {pagesSectionData &&
              pagesSectionData.data &&
              pagesSectionData.data.data.map((sectionData) => {
                return (
                  sectionData &&
                  sectionData.section_details

                    .filter(
                      (item) => item.section_code === "MANAGESUSTAINABILITY"
                    )[0]
                    .subSectionData.filter(
                      (item) =>
                        item.sub_section_title_code ===
                        "SUSTAINABLEOPERATIONS" ||
                        item.sub_section_title_code === "REDUCINGPLASTIC"
                    )
                    .map((subData) => {
                      let sub_section_title = subData.elements.find(
                        (item) => item.field_title_code === "HEADING"
                      )?.data;
                      //   let sub_section_title = subData.sub_section_title;
                      return (
                        <>
                          <div className="row sustainrow position-relative">
                            <div className="col-md-7">
                              <motion.div
                                initial={{ opacity: 0, x: -100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                className="feature-left-image"
                              >
                                {/* <img
                                  className="w-100"
                                  src={
                                    subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.data ??
                                      null)
                                  }
                                  alt={subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.img_alt_tag ??
                                      null)}
                                  title=""
                                /> */}
                                <Image
                                  className="w-100"
                                  src={
                                    subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.data ??
                                      null)
                                  }
                                  alt={subData &&
                                    (subData.elements.filter(
                                      (items) =>
                                        items.field_title_code ===
                                        "FEATUREIMAGE"
                                    )[0]?.img_alt_tag ??
                                      null)}
                                  title=""
                                />
                              </motion.div>
                            </div>
                            <motion.div
                              initial={{ opacity: 0, x: 100 }}
                              whileInView={{ opacity: 1, x: 0 }}
                              transition={{ duration: 1 }}
                              className="right-feature-text-col position-absolute bottom-0 end-0 top-0 d-flex align-items-center"
                            >
                              <div className="right-feature-wrap bg-white">
                                <div className="top-head-text">
                                  <h4 className="georgia text-uppercase">
                                    {sub_section_title}
                                  </h4>
                                </div>
                                <div className="feature-text-para position-relative">
                                  <p>
                                    {" "}
                                    {subData &&
                                      subData.elements.filter(
                                        (items) =>
                                          items.field_title_code ===
                                          "DESCRIPTION"
                                      )[0]?.data}
                                  </p>
                                  <div className="learnmore mt-xl-5 mt-4 d-none">
                                    <Link
                                      to="/coming-soon"
                                      className="text-decoration-none text-uppercase"
                                    >
                                      Learn More{" "}
                                      <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className="ms-2"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                          </div>
                        </>
                      );
                    })
                );
              })}
            {/* <div className='row position-relative section-padding'>
                            <div className='col-md-7 offset-md-5'>
                                <motion.div
                                    initial={{ opacity: 0, x: 100 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 1 }}
                                    className='feature-left-image'>
                                    <img className='w-100' src={coralConservation} alt='Purpose' title='Pupropse' />
                                </motion.div>
                            </div>
                            <motion.div
                                initial={{ opacity: 0, x: -100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                className='right-feature-text-col position-absolute bottom-0 start-0 top-0 d-flex align-items-center'>
                                <div className='right-feature-wrap bg-white'>
                                    <div className='top-head-text'>
                                        <h4 className='georgia text-uppercase'>Reducing Plastic</h4>
                                    </div>
                                    <div className='feature-text-para position-relative'>
                                        <p>For the well-being of the Indian Ocean and our planet, our resorts combat plastic pollution with on-site water bottling plants, eliminating the need for plastic bottled water on the islands. </p>
                                        <div className='learnmore mt-xl-5 mt-4 d-none'>
                                            <Link to='/coming-soon' className='text-decoration-none text-uppercase'>Learn More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default SustainabilityPage;
