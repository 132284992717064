import React, { useState } from 'react';
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import OffersTabItem from './OffersTabItem'
import coverOne from '../assets/images/lowest-price-assurance.jpg'
import coverTwo from '../assets/images/festival-season.jpg'

import TabImgOne from '../assets/images/all-offers.jpg'
import TabImgTwo from '../assets/images/kanifushi-offers.jpg'
import TabImgThree from '../assets/images/varu-tabs.jpg'
import TabImgFour from '../assets/images/gen-gallery-one.jpg'

// import TabImgFive from '../assets/images/lalgarh-tabs.jpg'
import { useQuery } from "react-query";
import { getOfferData } from "../services/dataServices";
const OffersTabs = ({slugOfOffers}) => {
  const [activeTab, setActiveTab] = useState(0);

  let AllOffers = useQuery(
    'getOfferData',
    getOfferData
  );

  // const resortNames = AllOffers?.data?.data?.map((item) => item.resort_name) || [];
  const resortNames = [...new Set(AllOffers?.data?.data?.map((item) => item.resort_name))] || [];

  const allTabs = [
    { coverId: 0, tabName: "ALL OFFERS", tabCircleImage: TabImgOne },
    ...resortNames?.map((resortName, index) => ({
      coverId: index + 1,
      tabName: resortName,
      tabCircleImage: index === 0 ? TabImgTwo : (index === 1 ? TabImgThree : TabImgFour),
      // tabCircleImage: index === 0 ? TabImgTwo : TabImgThree:TabImgFour, // Use appropriate images for each resort
    }))
  ];

  // const allTabs = [
  //   { coverId: 0, tabName: "ALL OFFERS", offerImage: coverOne, offerHeadingOne: "25% Off", offerSmallHeading: "SPECIAL OFFER ON DINING", tabPara: "Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...", offerHotelLocation: "KANIFUSHI - MALDIVES", name: 'Atmosphere', tabCircleImage: TabImgOne },

  //   { coverId: 1, tabName: "Atmosphere - Kanifushi", offerImage: coverTwo, offerHeadingOne: "25% Off", offerSmallHeading: "SPECIAL OFFER ON DINING", tabPara: "Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...", offerHotelLocation: "KANIFUSHI - MALDIVES", name: 'Atmosphere - Kanifushi', tabCircleImage: TabImgTwo },

  //   { coverId: 2, tabName: "By Atmosphere - Varu", offerImage: coverOne, offerHeadingOne: "25% Off", offerSmallHeading: "SPECIAL OFFER ON DINING", tabPara: "Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...", offerHotelLocation: "KANIFUSHI - MALDIVES", name: 'By Atmosphere - Varu', tabCircleImage: TabImgThree },

  //   // {coverId:3,tabName:"MARINA GATE",offerImage:coverOne,offerHeadingOne: "25% Off",offerSmallHeading:"SPECIAL OFFER ON DINING",tabPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply...",offerHotelLocation:"KANIFUSHI - MALDIVES", name: 'MarinaGate',tabCircleImage: TabImgFour}

  // ]
//   const updatedAllTabs = allTabs.map((tab, index) => {
//     const resortName = resortNames[index]?.resortName; // Get the corresponding resort name
//     return { ...tab, resortName }; // Add resortName to the existing tab object
// });

  const onTabClick = (e, index) => {

    setActiveTab(index);
  };

  return (
    <>
      <Tabs activeTab={activeTab} onTabClick={onTabClick} AllOffers={allTabs}>
        {allTabs?.map((item) => (
          <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active text-uppercase d-flex align-items-center' : 'text-uppercase d-flex align-items-center'}`}>
            <span className='tab-feature-img d-flex rounded-circle overflow-hidden'>
            <img alt={''} src={item.tabCircleImage} /></span>
            <span className='tab-name'>{item.tabName}</span>
          </Tab>
        ))}
      </Tabs>

      {/* {filteredData.map((screen, index) => ( */}
      {allTabs?.map((screen) => (
        <TabScreen
          key={screen.coverId}
          activeTab={activeTab}
          index={screen.coverId}
          className="all-offers-list mt-lg-5 mt-4"
        // You can add animation with adding a custom class 
        // activeTab === 0 || item.name === allTabs.tabName
        >
          {AllOffers && AllOffers?.data && AllOffers?.data?.data?.filter((item) => (activeTab === 0 ? true : item.resort_name === screen.tabName)).map(data => (

            <OffersTabItem slugOfOffers={slugOfOffers} key={data.website_offer_id} offerId={data.website_offer_id} offerImage={data.offer_image} offerImageAltTag={data.offer_image_alt_tag} offerHeadingOne={data.offer_name} offerSmallHeading={data.discount_percent} tabPara={data.description} offerHotelLocation={data.resort_name} hotelLink={data.hotelLink} tabCircleImage={data.tabCircleImage} offerLink={data.offerLink} bookLink={data.website_url} discountText={data.discount_text} slug={data.slug} />

          ))}
        </TabScreen>
      ))}

      {/* <TabItem/> */}

    </>
  )
}

export default OffersTabs