import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { getResortData } from "../services/dataServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function BookNow() {

  let currentDateTime = new Date();
  const currentDate = currentDateTime.getDate();
  const nextDate = new Date(currentDateTime.setDate(currentDate + 1))
  const [bookingStartDate, setStart] = useState(new Date());
  const [bookingEndDate, setEnd] = useState(nextDate);


  const [selectedItemResort, setSelectedItem] = useState();
  const allResortdata = useQuery('allresortdata', getResortData)


  /* when getting error from resort API than this static data use in else condition */
  const resortData = allResortdata?.data?.data == null ? [
    { brand_resort_id: 1, resort_code: "95216", resort_name: "Atmosphere - Kanifushi" },
    { brand_resort_id: 2, resort_code: "110184", resort_name: "By Atmosphere - Varu" },
  ] : allResortdata.data.data;



  const handleSelectChange = (event) => {
    const selectedItemCode = event.target.value;
    const selectedItemResort = resortData.find(
      (item) => item.resort_code === selectedItemCode
    );
    setSelectedItem(selectedItemResort);

    setFormData({
      ...formData,
      resort: selectedItemCode,
    });
  };

  const [formData, setFormData] = useState({
    resort: "",
    discountCode: "",
    iataCode: "",
    adultCount: "2",
    childrenCount: "0",
    roomCount: "1",
  });

  ///adult count
  const adultIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, adultCount: +formData.adultCount + 1 });
  };

  const adultDecrementBtn = (e) => {
    e.preventDefault();
    if (formData.adultCount > 1) {
      setFormData({ ...formData, adultCount: +formData.adultCount - 1 });
    }
  };
  /* End adult count */

  /* Start children count*/
  const childrenIncrement = (event) => {
    event.preventDefault();
    setFormData({ ...formData, childrenCount: +formData.childrenCount + 1 });
  };

  const childrenDecrement = (event) => {
    event.preventDefault();
    if (formData.childrenCount > 0) {
      setFormData({ ...formData, childrenCount: +formData.childrenCount - 1 });
    }
  };
  /* End children count*/

  /* Start Room count*/

  // const roomIncrementBtn = (event) => {
  //   event.preventDefault();
  //   setFormData({ ...formData, roomCount: +formData.roomCount + 1 });
  // };

  // const roomDecrementBtn = (event) => {
  //   event.preventDefault();
  //   if (formData.roomCount > 1) {
  //     setFormData({ ...formData, roomCount: +formData.roomCount - 1 });
  //   }
  // };
  /* End Room count*/

  const handleDiscountCodeChange = (event) => {
    setFormData({
      ...formData,
      discountCode: event.target.value,
    });
  };
  const handleIataCodeChange = (event) => {
    setFormData({
      ...formData,
      iataCode: event.target.value,
    });
  };


  // Format date to MM-DD-YYYY
  const formatDate = (date) => format(date, "MM/dd/yyyy");


  /*Submit form Action Start here */
  const handleSubmit = (event) => {
    event.preventDefault();

    // Access formData for submission or further processing

    const queryParams = new URLSearchParams();
    // queryParams.set('resortId',formData.resort)
    queryParams.set("adults", formData.adultCount);
    queryParams.set("children", formData.childrenCount);
    queryParams.set("datein", formatDate(bookingStartDate));
    queryParams.set("dateOut", formatDate(bookingEndDate));
    // queryParams.set("rooms", formData.roomCount);
    queryParams.set("discount", formData.discountCode);
    queryParams.set("iata", formData.iataCode);

    if (formData.resort > 0) {
      const newTab = window.open(
        `https://reservations.travelclick.com/${formData.resort
        }?${queryParams.toString()}`,
        "_blank"
      );
      // Focus on the new tab (optional)
      if (newTab) {
        newTab.focus();
      }
    } else {
      const newTab = window.open(
        `https://search.atmospherehotelsandresorts.com/ahr/ATMOSPHERE/`,
        "_blank"
      );
      // Focus on the new tab (optional)
      if (newTab) {
        newTab.focus();
      }
    }
  };
  /**End Submit form here **/
  
  // https://search.travelclick.com/ahr/ old link
  return (
    <>
      <section id="BookNow">
        <div className="atmosphere-book-now bg-white rounded-1 ms-auto me-auto z-1 position-relative">
          <form action="#" method="get">
            <div className="row">
              <div className="col-xl col-md-6">
                <Form.Label for="Resort" className="text-uppercase opacity-75">
                  Hotel/Resort
                </Form.Label>
                <Form.Select
                  className="form-control form-select shadow-none"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                >
                  <option value="" className="d-none" selected>Select</option>
                  <option value="" disabled className="py-4">Maldives</option>
                  {/* <option value="option1">Atmosphere - Kanifushi</option>
                  <option value="option2">By Atmosphere - Varu</option> */}

                  {/* <option value="" disabled>India</option>
                  <option value="option1">Lalgarh Palace, Rajsthan</option>
                  <option value="option2">Hari Mahahl, Jodhpur</option> */}
                  {resortData && resortData.map((resort) => (
                    <option key={resort.brand_resort_id} value={resort.resort_code}>
                      {resort.resort_name}
                    </option>
                  ))}
                </Form.Select>
              </div>
              {/* /col */}
              <div className="col-xl col-md-6">
                <Form.Label for="Resort" className="text-uppercase opacity-75">
                  Check in <span className="mx-1">-</span> Check out
                </Form.Label>
                <DateRangePicker
                  initialSettings={{
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                    startDate: bookingStartDate,
                    endDate: bookingEndDate,
                    minDate: new Date(),
                    autoApply: true,
                  }}
                  onApply={(event, picker) => {
                    setStart(new Date(picker.startDate));
                    setEnd(new Date(picker.endDate));
                  }}
                >
                  <input
                    type="button"
                    className="form-control shadow-none text-start form-select checkin-out-date"
                  />
                </DateRangePicker>
              </div>
              {/* /col */}
              <div className="col-xl col-md-6">
                <div className="booking-form-input guests-input position-relative">
                  <Form.Label
                    for="Resort"
                    className="form-label text-uppercase opacity-75 form-select-sm p-0"
                  >
                    Guests
                  </Form.Label>

                  <Dropdown>
                    <Dropdown.Toggle
                      name="guests-btn"
                      className="form-control   shadow-none guest-form-control"
                      id="guests-input-btn"
                    >
                      {formData.adultCount} Adult , {formData.childrenCount}{" "}
                      Children
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="guest-dropdown mw-100 mt-2">
                      <div className="row">
                        <div className="mb-4">
                          {/* /col-6 */}

                          <div
                            className="guests-input_options"
                            id="guests-input-options"
                          >

                            <div className="guest-dropdown-item">
                              <label>Adult</label>
                              <div className="guest-card d-flex align-items-center">
                                <span
                                  className="guests-input__ctrl minus"
                                  id="adults-subs-btn"
                                  onClick={adultDecrementBtn}
                                >
                                  <FontAwesomeIcon icon={faAngleLeft} />
                                </span>
                                {/* /.guests-input__ctrl */}
                                <span className="guests-input__value">
                                  <span id="guests-count-adults">
                                    <input
                                      type="hidden"
                                      value={formData.adultCount}
                                    />
                                    {formData.adultCount}
                                  </span>
                                </span>
                                {/* /.guests-input__value */}
                                <span
                                  className="guests-input__ctrl plus"
                                  id="adults-add-btn"
                                  onClick={adultIncrementBtn}
                                >
                                  <FontAwesomeIcon icon={faAngleRight} />
                                </span>
                                {/* /.guests-input__ctrl */}
                              </div>
                            </div>
                            <div className="guest-dropdown-item mt-3">
                              <label>Children</label>
                              <div className="guest-card d-flex align-items-center">
                                <span
                                  className="guests-input__ctrl minus"
                                  id="children-subs-btn"
                                  onClick={childrenDecrement}
                                >
                                  <FontAwesomeIcon icon={faAngleLeft} />
                                </span>
                                {/* /.guests-input__ctrl */}
                                <span className="guests-input__value">
                                  <span id="guests-count-children">
                                    <input
                                      type="hidden"
                                      value={formData.childrenCount}
                                    />
                                    {formData.childrenCount}
                                  </span>
                                </span>
                                {/* /.guests-input__value */}
                                <span
                                  className="guests-input__ctrl plus"
                                  id="children-add-btn"
                                  onClick={childrenIncrement}
                                >
                                  <FontAwesomeIcon icon={faAngleRight} />
                                </span>
                                {/* /.guests-input__ctrl */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                    {/* /guests-input__options */}
                  </Dropdown>
                </div>

                {/* /booking-form-input */}
              </div>
              {/* /col */}
              <div className="col-xl col-md-6">
                <div className="discount-wrap">
                  <Form.Label
                    for="DiscountCodes"
                    className="form-label opacity-75 text-uppercase"
                  >
                    Discount Codes
                  </Form.Label>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="form-control form-select bg-transparent shadow-none"
                      id="dropdown-basic"
                    >
                      Select Discount
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="discount-dropdown">
                      <div className="row">
                        <div className="mb-4">
                          <Form.Label
                            for="DiscountCode"
                            className="text-uppercase opacity-75"
                          >
                            Discount Code
                          </Form.Label>
                          <Form.Control
                            value={formData.discountCode}
                            type="text"
                            className="form-control shadow-none"
                            id="DiscountCode"
                            aria-describedby="passwordHelpBlock"
                            onChange={handleDiscountCodeChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Form.Label
                            for="IATACode"
                            className="text-uppercase opacity-75"
                          >
                            IATA Code
                          </Form.Label>
                          <Form.Control
                            value={formData.iataCode}
                            type="text"
                            className="form-control shadow-none"
                            id="IATACode"
                            aria-describedby="passwordHelpBlock"
                            onChange={handleIataCodeChange}
                          />
                        </div>
                        {/* /col-6 */}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/* /col */}
              <div className="col-xl submit-col">
                <div className="submit-form">
                  <Button
                    type="submit"
                    variant="primary theme-btn fw-medium text-white text-uppercase border-theme"
                    onClick={handleSubmit}
                  >
                    Check
                  </Button>
                </div>
              </div>
              {/* /col */}
            </div>
            {/* /row */}
          </form>
        </div>
      </section>
    </>
  );
}

export default BookNow;
