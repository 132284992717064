import React from 'react'
import Image from '../components/Image'

function DiscoverLeftItems({discoverImage,discoverTitle,discoverIcon,discoverDescription,discoverImageAltTag}) {
 
  return (
    <>
    <div className='col-lg-4 card-col'>
        <div className='discover-card h-100'>
            <div className='discover-card-image w-100'>
            <Image className='w-100' src={discoverImage} alt={discoverImageAltTag} title='' />
            {/* <img className='w-100' src={discoverImage} alt={discoverImageAltTag} title='' /> */}
            </div>
            <div className='discover-card-body w-100 position-relative d-flex align-items-center justify-content-center flex-column'>
                <div className='discover-feature-icon rounded-circle d-flex align-items-center justify-content-center'>
                    <img src={discoverIcon} alt='' title='' />
                </div>
                <div className='discover-title my-lg-3 mt-lg-4 my-2 georgia'>
                    {discoverTitle}
                </div>
                <div className='discover-text text-center'>
                    <p>{discoverDescription}</p>
                </div>
                </div>
        </div>
        </div>
    </>
  )
}

export default DiscoverLeftItems