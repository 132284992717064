import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import nextArrow from "../assets/images/next-arrow.svg";
import prevArrow from "../assets/images/prev-arrow.svg";
import HotelsResortsMobileSlider from "./HotelsResortsMobileSlider";
import { useQuery } from "react-query";
import { getPageData } from "../services/dataServices";
import Image from "./Image";
const gallerySettings = {
  arrow: true,
  dots: true,
  infinite: true,
  speed: 1500,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        centerMode: false,
      },
    },
  ],
};

let movecount = 0;
let currentSlide1 = 0;
const HotelsResortsSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [backgroundSlide, setbackgroundSlide] = useState(1);
  const [currentAnimation, setCurrentAnimation] = useState("initial"); // Set an initial animation state
  const [currentSlider1, setCurrentSlider1] = useState(0);
  const [currentSlider, setCurrentSlider] = useState(0);
  const [xcurrentSlider, setXCurrentSlider] = useState(0);
  const [ycurrentSlider, setYCurrentSlider] = useState(0);
  const [imgwcurrentSlider, setIMGWCurrentSlider] = useState(0);
  const [imghcurrentSlider, setIMGHCurrentSlider] = useState(0);
  const [textnextSlider, settextnextSlider] = useState(1);
  const pageCode = "HOME";
  const [slidestext, setslidestext] = useState([]);
  const [slidesImageAltTag, setSlidesImageAltTag] = useState([]);
  const [slides, setslides] = useState([]);
  const [dataArray, setdataArray] = useState([]);


  const pagesSectionData = useQuery(["pagesSectionData", pageCode], () =>
    getPageData(pageCode)
  );

  const moveFirstToLast = () => {
    if (slides.length > 1) {
      const [first, ...rest] = slides;

      const updatedSlides = [...rest, first];
      setslides(updatedSlides);
    }
  };

  useEffect(() => {
    pagesSectionData &&
      pagesSectionData.data &&
      pagesSectionData?.data?.data?.map((sectionData) => {
        const newSlidestext = [];
        const newSlides = [];
        const dataArray1 = [];
        const newSlidesImgAltTag =[];
        sectionData.section_details
          .filter((item) => item.section_code === "OURHOTELSANDRESORTS")[0]
          .subSectionData.map((subsectionData) => {
            const thumbnailTitle = subsectionData?.elements?.filter(
              (title) => title.field_title_code === "THUMBNAILTITLE"
            )[0]?.data;

            const thumbnailImage = subsectionData.elements.filter(
              (title) => title.field_title_code === "IMAGE"
            )[0].data;
            const thumbnailImageImgAltTag = subsectionData.elements.filter(
              (title) => title.field_title_code === "IMAGE"
            )[0].img_alt_tag;
            const heading = subsectionData?.elements?.filter(
              (title) => title.field_title_code === "HOTEL/RESORTNAME"
            )[0]?.data;
            const title = subsectionData?.elements?.filter(
              (title) => title.field_title_code === "SUBBRANDNAME"
            )[0]?.data;
            const desc = subsectionData?.elements?.filter(
              (title) => title.field_title_code === "LOCATION"
            )[0]?.data;
            const linkName = subsectionData?.elements?.filter(
              (title) => title.field_title_code === "EXPLOREBUTTON"
            )[0]?.data;

            // Assuming newSlidestext and newSlides have the same length and are non-empty

            const dataObject = {
              imageUrl: thumbnailImage,
              imageAltTag:thumbnailImageImgAltTag,
              heading: title,
              title: heading, // Use the appropriate variable here
              desc: desc, // Use the appropriate variable here
              linkName: "Explore", // Use the appropriate variable here
              linkTarget: "_blank", // Set the appropriate link target
              link: linkName, // Set the appropriate link
            };

            // Push the dataObject to dataArray
            dataArray1.push(dataObject);

            newSlidestext.push(thumbnailTitle);
            newSlides.push(thumbnailImage);
            newSlidesImgAltTag.push(thumbnailImageImgAltTag);
            // Add thumbnailTitle to slidestext array
          });

        setslidestext(newSlidestext);
        setSlidesImageAltTag(newSlidesImgAltTag)
        const newSlides1 =
          newSlides.length > 1
            ? [...newSlides.slice(1), newSlides[0]]
            : newSlides;

            // const newSlidesImgAltTag1 =
            // newSlidesImgAltTag.length > 1
            //   ? [...newSlidesImgAltTag.slice(1), newSlidesImgAltTag[0]]
            //   : newSlidesImgAltTag;

        // Use setslides to update the state with the new array
        // setslides(newSlidesImgAltTag);
        // setslides(newSlidesImgAltTag1);
        setslides(newSlides);
        setslides(newSlides1);
        setdataArray(dataArray1);
      });
  }, [pagesSectionData.data]);

  let sliderpercent = 0;

  useEffect(() => {
    // On page load, add a transition to the first slide-caption element
    const slideCaptions = document.querySelectorAll(".slide-caption");
    const firstSlideCaption = slideCaptions[textnextSlider];
    if (firstSlideCaption) {
      //firstSlideCaption.style.transition = 'transform 1s ease-in-out';
      firstSlideCaption.style.transform = "translateY(40px)";
    }
    settextnextSlider(textnextSlider + 1);
    slideCaptions.forEach(function (slideCaption, index) {
      if (index != 0) {
        //slideCaption.style.display = "none";
        slideCaption.style.opacity = "0";
      } else {
        // slideCaption.style.display = "block";
        slideCaption.style.opacity = "1";
      }
    });
  }, []);

  const handleButtonClick = (event, index) => {
    const animationType = "animation1";
    const nextbuttonorg = document.getElementById("nextbuttonorg");
    const nextbuttondummy = document.getElementById("nextbuttondummy");
    nextbuttonorg.style.display = "none";
    nextbuttondummy.style.display = "block";
    // nextbuttondummy.classList.add("not-pointer");

    const prevbuttonorg = document.getElementById("prevbuttonorg");
    const prevbuttondummy = document.getElementById("prevbuttondummy");
    prevbuttonorg.style.display = "none";
    prevbuttondummy.style.display = "block";
    // nextbuttondummy.classList.add("not-pointer");

    const newSlide = (index + 1) % slides.length;
    if (newSlide === 0)
      //setCurrentSlide(slides.length);
      setCurrentSlide((prevCount) => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return slides.length;
      });
    //setCurrentSlide(newSlide);
    else
      setCurrentSlide((prevCount) => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return newSlide;
      });
    setCurrentAnimation(animationType);
    getaxis();
    handleselectedMoveLeft(event, index);
    handleselectedtextchange(index);
    setTimeout(() => {
      const nextbuttonorg = document.getElementById("nextbuttonorg");
      const nextbuttondummy = document.getElementById("nextbuttondummy");
      nextbuttonorg.style.display = "block";
      nextbuttondummy.style.display = "none";

      const prevbuttonorg = document.getElementById("prevbuttonorg");
      const prevbuttondummy = document.getElementById("prevbuttondummy");
      prevbuttonorg.style.display = "block";
      prevbuttondummy.style.display = "none";
    }, 1500);
  };
  function handleselectedMoveLeft(event, currentSlide) {
    if (currentSlide === slides.length - 1) {
      setCurrentSlider1(0);
      sliderpercent = 100 / slides.length;
      setCurrentSlider1(sliderpercent);
      setCurrentSlider(sliderpercent + "%");
    } else {
      sliderpercent = currentSlider1 + 100 / slides.length;
      setCurrentSlider1(sliderpercent);
      setCurrentSlider(sliderpercent + "%");
    }
    if (currentSlide === slides.length - 1) {
      setCurrentSlide(0);
    }
    movecount = movecount + 1;
    var moveableDiv = document.getElementById("moveableDiv");

    // Get all elements within the moveableDiv
    var movableDivElements = moveableDiv.querySelectorAll(".thumb-wrap");

    var clickedElement = event.currentTarget;
    var previousElements = [];
    var foundCurrentSlide = false;
    // Iterate over elements and stop when reaching the clicked element
    movableDivElements.forEach(function (item) {
      if (!foundCurrentSlide) {
       

        if (item.getAttribute("key") === clickedElement.getAttribute("key")) {
          // If the current item is the clicked element, stop iterating
          foundCurrentSlide = true;
          return;
        }
        item.style.transition = "opacity 0.4s ease-in-out";
        item.style.opacity = "0";

        // Add the current item to the previousElements array
        previousElements.push(item);
      }
    });

    previousElements.forEach(function (thumbWrap, index) {
      var translation = -0; // Adjust the value as needed
      thumbWrap.style.transform = "translateX(" + translation + "%)";
      thumbWrap.style.opacity = 1;
      thumbWrap.style.display = "block";
      moveableDiv.appendChild(thumbWrap);
    });

    var thumbWrapshidden = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 1"])'
    );
    thumbWrapshidden.forEach(function (thumbWraphidden, index) {
      var translation = -230; // Adjust the value as needed
      thumbWraphidden.style.transform = "translateX(" + translation + "%)";
      thumbWraphidden.style.opacity = 1;
      // moveableDiv.appendChild(thumbWraphidden);
    });
    var thumbWraps = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 0"])'
    );
   
    var thumbimg = moveableDiv.querySelectorAll(
      '.thumb-img-box-unique:not([style*="display: none"])'
    );
    // var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="opacity: 0"])');
    var thumbimghidden = moveableDiv.querySelectorAll(".thumb-img-box-unique");

    thumbimghidden.forEach(function (thumbWraphidden, index) {
      //   if(index==0)
      //   thumbWraphidden.style.display = "none";
      // else
      thumbWraphidden.style.display = "block";
    });

    var firstThumbWrap = thumbWraps[0];
    var firstThumbWraphidden = thumbWrapshidden[0];
    var firstThumbimg = thumbimg[0];
    // var secondThumbWrap = thumbWraps[1].cloneNode(true);
    // var movetolast = secondThumbWrap;
    if (firstThumbWrap) {
      firstThumbimg.style.display = "none";
      firstThumbimg.style.cursor = "default";
      firstThumbWrap.style.opacity = 0;
      firstThumbWrap.classList.add("not-pointer");

      if (movecount) {
        firstThumbimg = thumbimg[1];
        firstThumbimg.style.display = "none";
      firstThumbimg.style.cursor = "default";
        firstThumbWrap.style.opacity = 0;
        firstThumbWrap.classList.add("not-pointer");
      }
    }

    setTimeout(function () {
      thumbimghidden.forEach(function (thumbWraphidden, index) {
        thumbWraphidden.style.display = "block";
      firstThumbimg.style.cursor = "pointer";

      });
     
      thumbWraps.forEach(function (thumbWrap, index) {
        var translation =
          index === 0
            ? -670
            : index === 1
            ? -560
            : index === 2
            ? -450
            : index === 3
            ? -340
            : index === 4
            ? -230
            : 0; // Adjust the value as needed
        thumbWrap.style.transform = "translateX(" + translation + "%)";
        thumbWrap.style.transition =
          "opacity 1s ease-in-out 0s, transform 1s ease-in-out 0s";
        // thumbWrap.style.transition = 'transform 1s ease-in-out 0s';
      });
    }, 10);

    var abc = 1;
  }

  function handleselectedtextchange(index) {
    const slideCaptions = document.querySelectorAll(
      '.slide-caption:not([style*="opacity: 0"])'
    );
    const slideCaptionshidden = document.querySelectorAll(
      '.slide-caption:not([style*="opacity: 1"])'
    );
    const firstSlideCaption = slideCaptions[0];
    if (firstSlideCaption) {
      firstSlideCaption.style.transition = "opacity 0.4s ease-in-out";
      firstSlideCaption.style.opacity = "0";
      firstSlideCaption.style.zIndex = "-1";
      // Optionally, you can remove the element after the fade-out effect
      setTimeout(() => {
        // firstSlideCaption.style.display = 'none';
      }, 1000); // Adjust the timeout based on the duration of the transition
    }

    slideCaptionshidden.forEach(function (firstSlideCaptionhidden, index) {
      firstSlideCaptionhidden.style.transform = "translateY(40px)";
    });

  
    let lastindex = index + 1;

    if (slideCaptionshidden.length == index) {
      lastindex = 0;
    }

    const slideCaptionHidden = document.querySelectorAll(
      '.slide-caption[data-id*="' + lastindex + '"]'
    );
    const slideCaptionhidden = slideCaptionHidden[0];
    if (slideCaptionhidden) {
      slideCaptionhidden.style.transition =
        "opacity 0.25s ease-in-out 0.2s,transform 1.25s ease-in-out 0s";
      slideCaptionhidden.style.transform = "translateY(0px)";
      slideCaptionhidden.style.opacity = "1";
      slideCaptionhidden.style.zIndex = "1";
      // After a delay, apply the final style
      setTimeout(function () {
        var moveableDiv = document.getElementById("initialhw1");
        moveableDiv.appendChild(firstSlideCaption);
        //firstSlideCaption.style.transform = 'translateY(120px)';
      }, 1500); // Adjust the timeout based on the duration of the transition
    }
    settextnextSlider(textnextSlider + 1);
    if (textnextSlider === slides.length) {
      settextnextSlider(1);
    }
  }

  const handlenextButtonClick = (animationType) => {
    animationType = "animation1";
    const nextbuttonorg = document.getElementById("nextbuttonorg");
    const nextbuttondummy = document.getElementById("nextbuttondummy");
    nextbuttonorg.style.display = "none";
    nextbuttondummy.style.display = "block";

    const prevbuttonorg = document.getElementById("prevbuttonorg");
    const prevbuttondummy = document.getElementById("prevbuttondummy");
    prevbuttonorg.style.display = "none";
    prevbuttondummy.style.display = "block";

    const newSlide = (currentSlide + 1) % slides.length;
   
    if (newSlide === 0) {
      //setCurrentSlide(slides.length);
      setCurrentSlide((prevCount) => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return slides.length;
      });
      currentSlide1 = slides.length;
      //setCurrentSlide(newSlide);
    } else {
      setCurrentSlide((prevCount) => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return newSlide;
      });
      currentSlide1 = newSlide;
    }
 
    setCurrentAnimation(animationType);
    getaxis();
    handleMoveLeft(currentSlide);
    handletextchange();
    handleselectedtextchange(currentSlide);
    setTimeout(() => {
      const nextbuttonorg = document.getElementById("nextbuttonorg");
      const nextbuttondummy = document.getElementById("nextbuttondummy");
      nextbuttonorg.style.display = "block";
      nextbuttondummy.style.display = "none";

      const prevbuttonorg = document.getElementById("prevbuttonorg");
      const prevbuttondummy = document.getElementById("prevbuttondummy");
      prevbuttonorg.style.display = "block";
      prevbuttondummy.style.display = "none";
    }, 1500);
  };

  function handletextchange() {
    const slideCaptions = document.querySelectorAll(
      '.slide-caption:not([style*="opacity: 0"])'
    );
    const slideCaptionshidden = document.querySelectorAll(
      '.slide-caption:not([style*="opacity: 1"])'
    );
    const firstSlideCaption = slideCaptions[0];
    if (firstSlideCaption) {
      firstSlideCaption.style.transition = "opacity 0.4s ease-in-out";
      firstSlideCaption.style.opacity = "0";
      firstSlideCaption.style.zIndex = "-1";
      // Optionally, you can remove the element after the fade-out effect
      setTimeout(() => {
        // firstSlideCaption.style.display = 'none';
      }, 1000); // Adjust the timeout based on the duration of the transition
    }

    slideCaptionshidden.forEach(function (firstSlideCaptionhidden, index) {
      firstSlideCaptionhidden.style.transform = "translateY(40px)";
    });

    const slideCaptionhidden = slideCaptionshidden[0];
    if (slideCaptionhidden) {
      slideCaptionhidden.style.transition =
        "opacity 0.25s ease-in-out 0.2s,transform 1.25s ease-in-out 0s";
      slideCaptionhidden.style.transform = "translateY(0px)";
      slideCaptionhidden.style.opacity = "1";
      slideCaptionhidden.style.zIndex = "1";
      // After a delay, apply the final style
      setTimeout(function () {
        var moveableDiv = document.getElementById("initialhw1");
        moveableDiv.appendChild(firstSlideCaption);
        //firstSlideCaption.style.transform = 'translateY(120px)';
      }, 1500); // Adjust the timeout based on the duration of the transition
    }
    settextnextSlider(textnextSlider + 1);
    if (textnextSlider === slides.length) {
      settextnextSlider(1);
    }
  }

  function getaxis() {
    var moveableDiv = document.getElementById("moveableDiv");
    var referenceDiv = document.getElementById("referenceDiv");
  
    var thumbWraps = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 0"])'
    );
 

    if (thumbWraps.length > 0) {
      var firstThumbWrap = thumbWraps[0];
      var rectThumbWrap = firstThumbWrap.getBoundingClientRect();
      var rectReferenceDiv = referenceDiv.getBoundingClientRect();

      var xRelativeToReferenceDiv = rectThumbWrap.left - rectReferenceDiv.left;
      var yRelativeToReferenceDiv = rectThumbWrap.top - rectReferenceDiv.top;
      var imgElement = firstThumbWrap.querySelector("img");
 
      if (imgElement) {
        var imgWidth = imgElement.width;
        var imgHeight = imgElement.height;
        var rectImg = imgElement.getBoundingClientRect();
        var xImgRelativeToReferenceDiv = rectImg.left - rectReferenceDiv.left;
        var yImgRelativeToReferenceDiv = rectImg.top - rectReferenceDiv.top;

        setIMGWCurrentSlider(imgWidth);
        setIMGHCurrentSlider(imgHeight);
        setXCurrentSlider(xImgRelativeToReferenceDiv);
        setYCurrentSlider(yImgRelativeToReferenceDiv);
        
      }
    } else {
    }
  }

  function handleMoveLeft(currentSlide) {
    if (currentSlide === slides.length - 1) {
      setCurrentSlider1(0);
      sliderpercent = 100 / slides.length;
      setCurrentSlider1(sliderpercent);
      setCurrentSlider(sliderpercent + "%");
    } else {
      sliderpercent = currentSlider1 + 100 / slides.length;
      setCurrentSlider1(sliderpercent);
      setCurrentSlider(sliderpercent + "%");
    }
    if (currentSlide === slides.length - 1) {
      setCurrentSlide(0);
      
    }
    movecount = movecount + 1;
    var moveableDiv = document.getElementById("moveableDiv");

    var thumbWraps = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 0"])'
    );
    var thumbWrapshidden = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 1"])'
    );
    var thumbimg = moveableDiv.querySelectorAll(
      '.thumb-img-box-unique:not([style*="display: none"])'
    );
    // var thumbimg = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="opacity: 0"])');

  //   var thumbimgnothidden = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="opacity: 0"])');
  //   thumbimgnothidden.forEach(function (thumbWraphidden, index) {
  //    thumbWraphidden.style.cursor = "pointer";
  //  });
    var thumbimghidden = moveableDiv.querySelectorAll(".thumb-img-box-unique");

    thumbimghidden.forEach(function (thumbWraphidden, index) {
      //   if(index==0)
      //   thumbWraphidden.style.display = "none";
      // else
      thumbWraphidden.style.display = "block";
    });

    var firstThumbWrap = thumbWraps[0];
    var firstThumbWraphidden = thumbWrapshidden[0];
    var firstThumbimg = thumbimg[0];
    var secondThumbWrap = thumbWraps[1].cloneNode(true);
    var movetolast = secondThumbWrap;

    firstThumbimg.style.display = "none";
    firstThumbimg.style.cursor = "default";
    firstThumbWrap.style.opacity = 0;
    if (movecount) {
      firstThumbimg = thumbimg[1];
      firstThumbimg.style.display = "none";
    firstThumbimg.style.cursor = "default";
    firstThumbWrap.style.opacity = 0;
    }
    thumbWraps.forEach(function (thumbWrap, index) {
      var translation =
        index === 0
          ? -670
          : index === 1
          ? -560
          : index === 2
          ? -450
          : index === 3
          ? -340
          : index === 4
          ? -230
          : 0; // Adjust the value as needed
      thumbWrap.style.transform = "translateX(" + translation + "%)";
      thumbWrap.style.transition =
        "opacity 1s ease-in-out 0s, transform 1s ease-in-out 0s";
      // thumbWrap.style.transition = 'transform 1s ease-in-out 0s';
    });

    setTimeout(function () {
      thumbimghidden.forEach(function (thumbWraphidden, index) {
        thumbWraphidden.style.display = "block";
      });
      thumbWrapshidden.forEach(function (thumbWraphidden, index) {
        var translation = -230; // Adjust the value as needed
        thumbWraphidden.style.transform = "translateX(" + translation + "%)";
        thumbWraphidden.style.opacity = 1;
        

        moveableDiv.appendChild(thumbWraphidden);
      });
    }, 1500);

    var abc = 1;
  }

  const handlepreviousButtonClick = (animationType) => {
    const nextbuttonorg = document.getElementById("nextbuttonorg");
    const nextbuttondummy = document.getElementById("nextbuttondummy");
    nextbuttonorg.style.display = "none";
    nextbuttondummy.style.display = "block";

    const prevbuttonorg = document.getElementById("prevbuttonorg");
    const prevbuttondummy = document.getElementById("prevbuttondummy");
    prevbuttonorg.style.display = "none";
    prevbuttondummy.style.display = "block";

    const newSlide = (currentSlide - 1 + slides.length) % slides.length;
    if (newSlide === 0)
      //setCurrentSlide(slides.length);
      setCurrentSlide((prevCount) => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return slides.length;
      });
    // setCurrentSlide(newSlide);
    else
      setCurrentSlide((prevCount) => {
        //const newSlide = (currentSlide - 1 + slides.length) % slides.length;
        // Your logic here
        return newSlide;
      });
    setCurrentAnimation(animationType);
    getprevaxis();
    handleMoveright();
    handleprevtextchange();
    handleselectedtextchange(newSlide-1);
    setTimeout(() => {
      const nextbuttonorg = document.getElementById("nextbuttonorg");
      const nextbuttondummy = document.getElementById("nextbuttondummy");
      nextbuttonorg.style.display = "block";
      nextbuttondummy.style.display = "none";

      const prevbuttonorg = document.getElementById("prevbuttonorg");
      const prevbuttondummy = document.getElementById("prevbuttondummy");
      prevbuttonorg.style.display = "block";
      prevbuttondummy.style.display = "none";
    }, 1500);
  };

  function handleprevtextchange() {
    const slideCaptionsall = document.querySelectorAll(".slide-caption");
    const slideCaptions = document.querySelectorAll(
      '.slide-caption:not([style*="opacity: 0"])'
    );
    const slideCaptionshidden = document.querySelectorAll(
      '.slide-caption:not([style*="opacity: 1"])'
    );

    const firstSlideCaption = slideCaptions[0];
    if (firstSlideCaption) {
      firstSlideCaption.style.transition =
        "opacity 0.8s ease-in-out 0s, transform 1s ease-in-out 0s";
      //firstSlideCaption.style.transition = 'transform 2s ease-in-out';
      firstSlideCaption.style.opacity = "0";
      firstSlideCaption.style.zIndex = "-1";
      firstSlideCaption.style.transform = "translateY(40px)";
      // Optionally, you can remove the element after the fade-out effect
      setTimeout(() => {
        
      }, 1000); // Adjust the timeout based on the duration of the transition
    }

    slideCaptionshidden.forEach(function (firstSlideCaptionhidden, index) {
      firstSlideCaptionhidden.style.transform = "translateY(0px)";
    });
    const firstelementindiv = slideCaptionsall[0];
    const slideCaptionhidden =
      slideCaptionshidden[slideCaptionshidden.length - 1];
    if (slideCaptionhidden) {
      slideCaptionhidden.style.transition = "opacity 1s ease-in";
      //slideCaptionhidden.style.transform = 'translateY(0px)';
      slideCaptionhidden.style.opacity = "1";
      slideCaptionhidden.style.zIndex = "1";
      // After a delay, apply the final style
      setTimeout(function () {
        var moveableDiv = document.getElementById("initialhw1");
        moveableDiv.insertBefore(slideCaptionhidden, firstelementindiv);
        slideCaptionhidden.style.transform = "translateY(0px)";
      }, 1500); // Adjust the timeout based on the duration of the transition
    }
    settextnextSlider(textnextSlider + 1);
    if (textnextSlider === slides.length) {
      settextnextSlider(1);
    }
  }

  function getprevaxis() {
    var moveableDiv = document.getElementById("moveableDiv");
    var referenceDiv = document.getElementById("referenceDiv");

    var thumbWraps = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 0"])'
    );

    if (thumbWraps.length > 0) {
      var firstThumbWrap = thumbWraps[0];
      var rectThumbWrap = firstThumbWrap.getBoundingClientRect();
      var rectReferenceDiv = referenceDiv.getBoundingClientRect();

      var imgElement = firstThumbWrap.querySelector("img");

      if (imgElement) {
        var imgWidth = imgElement.width;
        var imgHeight = imgElement.height;
        var rectImg = imgElement.getBoundingClientRect();
        var xImgRelativeToReferenceDiv = rectImg.left - rectReferenceDiv.left;
        var yImgRelativeToReferenceDiv = rectImg.top - rectReferenceDiv.top;
        setIMGWCurrentSlider(imgWidth);
        setIMGHCurrentSlider(imgHeight);
        setXCurrentSlider(xImgRelativeToReferenceDiv);
        setYCurrentSlider(yImgRelativeToReferenceDiv);
      }
    } else {
    }
  }

  function handleMoveright() {
    if (currentSlide === 0) {
      setCurrentSlider1(0);
      sliderpercent = 100 / 1;
      setCurrentSlider1(sliderpercent);
      setCurrentSlider(sliderpercent + "%");
    } else {
      sliderpercent = currentSlider1 - 100 / slides.length;
      setCurrentSlider1(sliderpercent);
      setCurrentSlider(sliderpercent + "%");
    }
    if (currentSlide === 1) {
      setCurrentSlide(0);
    
    }
    movecount = movecount + 1;

    var moveableDiv = document.getElementById("moveableDiv");

    var thumbWraps = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 0"])'
    );
    var thumbWrapshidden = moveableDiv.querySelectorAll(
      '.thumb-wrap:not([style*="opacity: 1"])'
    );
    var thumbimg = moveableDiv.querySelectorAll(
      '.thumb-img-box-unique:not([style*="display: none"])'
    );
    //  var thumbimgnothidden = moveableDiv.querySelectorAll('.thumb-img-box-unique:not([style*="opacity: 0"])');
    //  thumbimgnothidden.forEach(function (thumbWraphidden, index) {
    //   thumbWraphidden.style.cursor = "pointer";
    // });
    var thumbimghidden = moveableDiv.querySelectorAll(".thumb-img-box-unique");

   
    var firstThumbWrap = thumbWraps[0];
    var firstThumbWraphidden = thumbWrapshidden[0];
    var firstThumbimg = thumbimg[0];
   // firstThumbimg.style.cursor="pointer";
    var secondThumbWrap = thumbWraps[1].cloneNode(true);
    var movetolast = secondThumbWrap;

    thumbimghidden.forEach(function (thumbWraphidden, index) {
      thumbWraphidden.style.display = "block";
    });
    thumbWrapshidden.forEach(function (thumbWraphidden, index) {
      var translation = -560; // Adjust the value as needed
      //thumbWraphidden.style.display = "block";
      thumbWraphidden.style.transition =
        "opacity 1s ease-in 0s, transform 0.5s ease-in 0s";

      thumbWraphidden.style.transform = "translateX(" + translation + "%)";
      thumbWraphidden.style.opacity = 1;
      thumbWraphidden.classList.remove("not-pointer");
      //moveableDiv.insertBefore(thumbWraphidden,firstThumbWrap);
    });

    thumbWraps.forEach(function (thumbWrap, index) {
      // var translation = index == 0 ? -670 : index == 1 ? -560 : index == 2 ? -450 : index == 3 ? -340 : index == 4 ? -230 : 0; // Adjust the value as needed
      var translation =
        index === thumbWraps.length - 1
          ? -670
          : index === 0
          ? -450
          : index === 1
          ? -340
          : index === 2
          ? -230
          : 0; // Adjust the value as needed
      thumbWrap.style.transform = "translateX(" + translation + "%)";

      if (index === thumbWraps.length - 1) {
        thumbWrap.style.opacity = 0;
        thumbWrap.style.transition = "opacity 0s ease-in-out 0s";
        thumbWrap.style.transition =
          "opacity 0s ease-in 0s, transform 1.9s ease-in 0s";
      } else {
        thumbWrap.style.transition =
          "opacity 1s ease-in 0s, transform 0.5s ease-in 0s";
      }
     
    });

    var lastElement = thumbWraps[slides.length - 2];

    if (lastElement) {
      var imgElement = lastElement.querySelector("img");

      // Now you can work with imgElement and buttonElement
      if (imgElement) {
      
       imgElement.style.display = "none";
       // imgElement.style.cursor = "pointer";
      }
      lastElement.style.opacity = 0;
    }
    setTimeout(function () {
      thumbWrapshidden.forEach(function (thumbWraphidden, index) {
        moveableDiv.insertBefore(thumbWraphidden, firstThumbWrap);
      });
    
    }, 1500);

    var abc = 1;
  }

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
  };
  let count = 0;
  const getThumbnailOrder = () => {
    let order = [];
    if (count == 1) order = [2, 3, 0, 1, 2];
    else order = [3, 0, 1, 2, 3];
    count = 1;
    
    return order;
  };

  const onetine = 0;
  let singletime = 0;
  
  useEffect(() => {
    // Get the container element where you want to append the divs
    var container = document.getElementById("moveableDiv");
  
    // Loop over the slides array and create div elements for each slide
    slides.forEach(function (slide, index) {
      // Create a new div element
      var newDiv = document.createElement("div");
      newDiv.className = `thumb-wrap text-center ${index}`;
      newDiv.style.opacity = "1";
      // Create and append a span element for the text
      var span = document.createElement("span");
      span.className = "text-uppercase";

      if (index == slides.length - 1) span.textContent = slidestext[0];
      else span.textContent = slidestext[index + 1];

   
      newDiv.setAttribute("key", index);
      
      newDiv.appendChild(span);
      newDiv.addEventListener("click", function (event) {
        handleButtonClick(event, index);
      });
      // Create and append a div element for the image
      var imgDiv = document.createElement("div");
      imgDiv.className = "thumb-img-box ms-auto me-auto overflow-hidden";

      var img = document.createElement("img");
      img.className = "thumb-img-box-unique";
      img.src = slide;
      img.alt = slidesImageAltTag[index+1] ;
      //img.title = "thumbnail-" + (index + 1);
      img.style.cursor = "pointer";
      imgDiv.appendChild(img);
      newDiv.appendChild(imgDiv);

      // Append the new div to the container
      if (singletime == 0) {
        // var translation = index == slides.length-1 ? -670 :index == 0 ? -560 : index == 1 ? -450 : index == 2 ? -340 : index == 3 ? -230 : 0; // Adjust the value as needed
        var translation =
          index == slides.length - 1
            ? -670
            : index == 0
            ? -560
            : index == 1
            ? -450
            : index == 2
            ? -340
            : index == 3
            ? -230
            : 0; // Adjust the value as needed

        // Apply the fade-out and translation transformations

        if (index == slides.length - 1) {
          newDiv.style.transform = "translateX(" + translation + "%)";
          newDiv.style.opacity = 0;
          newDiv.style.transform = "scale(0)";
          container.appendChild(newDiv);
        } else {
          newDiv.style.transform = "translateX(" + translation + "%)";
          container.appendChild(newDiv);
        }

        sliderpercent = currentSlider1 + 100 / slides.length;
        setCurrentSlider1(sliderpercent);
        setCurrentSlider(sliderpercent + "%");

        var moveableDiv = document.getElementById("moveableDiv");
        var referenceDiv = document.getElementById("referenceDiv");

        var thumbWraps = moveableDiv.querySelectorAll(
          '.thumb-wrap:not([style*="opacity: 0"])'
        );
        var thumbWrapshidden = moveableDiv.querySelectorAll(
          '.thumb-wrap:not([style*="opacity: 1"]) img'
        );
        thumbWrapshidden.forEach(function (imgElement) {
          imgElement.style.display = "none";
        });
        if (thumbWraps.length > 0) {
          var firstThumbWrap = thumbWraps[0];
          var rectThumbWrap = firstThumbWrap.getBoundingClientRect();
          var rectReferenceDiv = referenceDiv.getBoundingClientRect();

          var xRelativeToReferenceDiv =
            rectThumbWrap.left - rectReferenceDiv.left;
          var yRelativeToReferenceDiv =
            rectThumbWrap.top - rectReferenceDiv.top;
          var imgElement = firstThumbWrap.querySelector("img");

          if (imgElement) {
            var imgWidth = imgElement.width;
            var imgHeight = imgElement.height;
          }
          setIMGWCurrentSlider(imgWidth);
          setIMGHCurrentSlider(imgHeight);
          setXCurrentSlider(xRelativeToReferenceDiv);
          setYCurrentSlider(yRelativeToReferenceDiv);
        } else {
        }
    
      }
    });
    singletime = 1;
  }, [slidestext]);


  return (
    <>
      <div className="hotels-resorts-slides position-relative mt-lg-4 mt-3 d-md-block d-none">
        <div
          className="slide-demo-box w-100"
          style={{
            backgroundImage: `url(${
              slides[
                currentAnimation === "animation1" ||
                currentAnimation === "initial"
                  ? currentSlide == 0 || currentSlide == 1
                    ? slides.length - 1
                    : currentSlide - 2
                  : currentSlide === 0
                  ? slides.length - 1
                  : currentSlide - 1
              ]
            })`,

            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat", // Ensure the background doesn't repeat
          }}
        ></div>
        <div
          id="initialhw1"
          className="img-container position-absolute start-0 top-0 w-100 h-100  initialhw"
        >
          {dataArray.map((item, index) => (
            <div
              key={index}
              data-id={index}
              className={`slide-caption text-uppercase text-center text-white position-absolute  start-0 top-0 ${
                index === 0 ? "pointer" : ""
              }`}
              style={{
                zIndex: index === 0 ? 1 : -1,
                opacity: index === 0 ? 1 : 0,
              }}
            >
              <h1 className="mt-3 mb-0 georgia">{item.title}</h1>
              <span className="slide-small-text position-relative pb-3 mb-3 d-flex w-100 justify-content-center">
                {item.heading}
              </span>
              <small>{item.desc}</small>
              <div className="slide-action d-flex justify-content-center">
                {item.link ? (
                  <Link
                    target={item.linkTarget}
                    to={item.link ? item.link : ""}
                    className="text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center"
                  >
                    {item.link ? item.linkName : "COMING SOON"}{" "}
                    <FontAwesomeIcon
                      icon={faArrowRightLong}
                      className="ms-3 icon-font"
                    />
                  </Link>
                ) : (
                  <span className="text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center">
                    COMING SOON{" "}
                    <FontAwesomeIcon
                      icon={faArrowRightLong}
                      className="ms-3 icon-font"
                    />
                  </span>
                )}
              </div>
            </div>
          ))}
    
          <AnimatePresence>
            <div id="referenceDiv"></div>
            <motion.div
              key={currentSlide}
              className="img-container-overlay d-flex align-items-end position-relative overflow-hidden "
              style={{ cursor: "pointer" }}
              initial={
                currentAnimation === "animation1"
                  ? {
                      opacity: 1,
                      x: xcurrentSlider,
                      y: ycurrentSlider,
                      width: imgwcurrentSlider,
                      height: imghcurrentSlider,
                    }
                  : currentAnimation === "animation2"
                  ? {
                      opacity: 1,
                      x: 0,
                      y: 0,
                      scale: 1,
                      width: "100%",
                      height: "100%",
                    }
                  : {}
              }
              animate={
                currentAnimation === "animation1"
                  ? {
                      opacity: 1,
                      x: 0,
                      y: 0,
                      scale: 1,
                      width: "100%",
                      height: "100%",
                    }
                  : currentAnimation === "animation2"
                  ? {
                      opacity: 1,
                      x: xcurrentSlider,
                      y: ycurrentSlider,
                      width: imgwcurrentSlider,
                      height: imghcurrentSlider,
                      //zIndex: 999,
                    }
                  : {}
              }
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
            <Image
                className="w-100 "
                src={
                  slides[
                    currentAnimation === "animation1" ||
                    currentAnimation === "initial"
                      ? currentSlide == 0
                        ? slides.length - 1
                        : currentSlide - 1
                      : currentSlide === slides.length
                      ? 0
                      : currentSlide
                  ]
                }
                alt={
                  slidesImageAltTag[currentSlide
                  ]
                }
                
                //slidesImageAltTag[index + 1]
                //alt={`hotel-resort-slide-${currentSlide + 1}`}
                title={`hotel-resort-slide-${currentSlide + 1}`}
                style={{ cursor: "pointer" }}
              />
              {/* <img
                className="w-100 "
                src={
                  slides[
                    currentAnimation === "animation1" ||
                    currentAnimation === "initial"
                      ? currentSlide == 0
                        ? slides.length - 1
                        : currentSlide - 1
                      : currentSlide === slides.length
                      ? 0
                      : currentSlide
                  ]
                }
                alt={
                  slidesImageAltTag[currentSlide
                  ]
                }
                
                //slidesImageAltTag[index + 1]
                //alt={`hotel-resort-slide-${currentSlide + 1}`}
                title={`hotel-resort-slide-${currentSlide + 1}`}
                style={{ cursor: "pointer" }}
              /> */}
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="slider-bottom-actions d-flex justify-content-between">
          <div className="slide-wrap d-flex align-items-center mt-xl-5 mt-4">
            <div className="left-control-btns d-flex">
              <div
                id="prevbuttonorg"
                className="arrow-right"
                onClick={() => handlepreviousButtonClick("animation2")}
              >
                <span
                  id="previousbutton"
                  className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center"
                >
                  <img src={prevArrow} alt="Next" title="Next" />
                </span>
              </div>
              <div
                id="prevbuttondummy"
                className="arrow-right"
                style={{ display: "none" }}
              >
                <span
                  id="previousbutton"
                  className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center"
                >
                  <img src={prevArrow} alt="Next" title="Next" />
                </span>
              </div>
              {/* {(currentSlide<=slides.length-1) && currentSlide!=0? */}
              <div
                id="nextbuttonorg"
                className="arrow-right"
                onClick={() => handlenextButtonClick("animation1")}
              >
                <button
                  id="nextbutton"
                  className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center ms-2 ms-lg-3"
                >
                  <img src={nextArrow} alt="Next" title="Next" />
                </button>
              </div>
              <div
                className="arrow-right"
                id="nextbuttondummy"
                style={{ display: "none" }}
              >
                <button className="slide-arrow-btns rounded-circle d-flex justify-content-center align-items-center ms-2 ms-lg-3">
                  <img src={nextArrow} alt="Next" title="Next" />
                </button>
              </div>
              {/* :""} */}
            </div>
            <div className="right-slide-progress-bar d-flex align-items-center">
              <div className="line-slide-status position-relative">
                <span className="linebar-slide"></span>
                <span
                  className="active-slide-bar position-absolute start-0"
                  // style={{ width: currentSlider }}
                ></span>
              </div>
              <div className="slide-number text-end">
                <span>{currentSlide + 1}</span>
              </div>
            </div>
          </div>

          <div
            id="moveableDiv"
            className="bottom-right-images ms-auto d-flex align-items-center justify-content-start"
          >
            
          </div>
        </div>
      </div>

      <div className="brands-slide d-block d-md-none">
        <div className="mobile-slides-hotel-resort mt-4">
          <HotelsResortsMobileSlider
            gallerySettings={gallerySettings}
            images={dataArray}
          />
        </div>
      </div>
    </>
  );
};

export default HotelsResortsSlides;