import { React, useState, useEffect } from 'react'
import parse from 'html-react-parser'

import AwardCard from './AwardCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { getAllAwards, getResortName } from '../../src/services/dataServices'
import { useQuery } from 'react-query';
function MediaAwards({ manageMedia }) {
  const [selectedResort, setSelectedResort] = useState("");
  const allAwards = useQuery("getAllAwards", getAllAwards);
  const resortName = useQuery("getResortName", getResortName);

  const allAwardsData = allAwards?.data?.data;
  const resortOptions = resortName?.data?.data;


// Filter resortOptions to include only those with corresponding awards
const filteredResortOptions = resortOptions?.filter((option) =>
  allAwardsData?.some((award) => award.website_id === option.website_id)
);

const defaultWebsiteId = filteredResortOptions?.map(
  (option) => option.website_id
)?.[0];


  // Default website id
  const selectedWebsiteId = selectedResort ? parseInt(selectedResort) : defaultWebsiteId;

  const awardsData = allAwards?.data?.data?.filter((item) => item.website_id === selectedWebsiteId).sort((a, b) => a.sorting_index - b.sorting_index);;


  const handleChange = (e) => {
    setSelectedResort(e.target.value);
  }


  /*******************************MEDIA AWARDS START********************************* */
  let pressReleasesAwardsData = manageMedia?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "AWARDS"
  );
  const awardsIcon =
    pressReleasesAwardsData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "CHANGEICON"
    )[0]?.data ?? null;

  const awardsTitle =
    pressReleasesAwardsData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "TITLE"
    )[0]?.data ?? null;

  const awardsHeading =
    pressReleasesAwardsData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;

  const awardsDescription =
    pressReleasesAwardsData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";

  /*******************************MEDIA AWARDS END********************************* */
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
              <img src={awardsIcon} title='Atmosphere Living' alt='Atmosphere Living' />
            </div>
            <div className='small-heading'>
              <span className='text-uppercase'>{awardsTitle}</span>
            </div>
            <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{awardsHeading}</h1>
            <div className='media-para m-auto pt-3 text-center'>
              <p>
                {parse(awardsDescription)}
              </p>
            </div>
          </div>

        </div>
        <div className='row justify-content-end'>
          <div className="col-lg-4">
            <div className="mb-4">
              {/* <label className="form-label" htmlFor="OfferType">
                Resort
              </label> */}
              <select
                name="resortId"
                id="OfferType"
                className="form-control form-select py-3 shadow-none px-4"
                onChange={handleChange}

              >
                {filteredResortOptions?.map((option) => (
                  <option key={option.website_id}
                    value={option.website_id}

                  >
                    {option.resort_name}
                  </option>
                ))}

              </select>
            </div>
          </div>
        </div>

        <div className='awards-list'>
          <div className='row gy-4'>
            {awardsData?.map((award) => (
              <div className='col-lg-4 col-md-6' key={award.award_id}>
                <AwardCard
                  awardName={award.name}
                  awardee={award.awardee}
                  year={award.year}
                />
              </div>
            ))}

          </div>
          <div className='show-more d-flex justify-content-center d-none'>
            <Link to='/' className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control'>Show More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaAwards