import React from "react";
import parse from "html-react-parser";
import pressReleases from "../assets/images/press-releases.svg";
import PressReleaseCard from "./PressReleaseCard";
import pressOne from "../assets/images/press-one.jpg";
import pressTwo from "../assets/images/press-two.jpg";
import pressThree from "../assets/images/press-three.jpg";
import pressFour from "../assets/images/press-four.jpg";
import pressFive from "../assets/images/press-five.jpg";
import pressSix from "../assets/images/press-six.jpg";
import pressSeven from "../assets/images/press-seven.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllPressRelease } from "../services/dataServices";
import Image from "../components/Image";
//import parse from 'html-react-parser';

function MediaPressRelease({
  manageMedia,
  slugOfMedia
}) {
 
 
  let allPressRelease = useQuery("getAllPressRelease", getAllPressRelease);


  /*******************************MEDIA PRESSREALEASES START********************************* */
  let pressReleasesData = manageMedia?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "PRESSRELEASES"
  );
  const pressRealseIcon =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "CHANGEICON"
    )[0]?.data ?? null;

  const pressRealseTitle =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "TITLE"
    )[0]?.data ?? null;

  const pressRealseHeading =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;

  const pressRealseDescription =
    pressReleasesData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";


  /*******************************MEDIA PRESSREALEASES END********************************* */
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading-icon mb-xl-4 mb-2 pt-2 pb-2">
              {/* <img
                src={pressRealseIcon}
                title="Atmosphere Living"
                alt="Atmosphere Living"
              /> */}
              <Image
                src={pressRealseIcon}
                title="Atmosphere Living"
                alt="Atmosphere Living"
              />
            </div>
            <div className="small-heading">
              <span className="text-uppercase">{pressRealseTitle}</span>
            </div>
            <h1 className="text-uppercase georgia heading-space mt-xl-4 mt-2">
              {pressRealseHeading}
            </h1>
            <div className="media-para m-auto pt-3 text-center">
              <p>{parse(pressRealseDescription)}</p>
            </div>
          </div>
          <div className="press-releases-cards mt-lg-5 mt-4">
            <div className="row gy-5">
              {allPressRelease &&
                allPressRelease.data &&
                allPressRelease.data.data.map((press) => (
                  <div
                    className="col-lg-6"
                    key={press.website_press_release_id}
                  >
                    <PressReleaseCard
                      cardImage={
                        press.image_path ? press.image_path : pressSeven
                      }
                      cardImageAltTag={
                        press.image_alt_tag 
                      }
                      cardTitle={press.title}
                      cardDate={press.formattedDateTime}
                      cardDescription={press.description}
                      cardLink={"Learn More"}
                      cardUrl={`/${slugOfMedia}/${press.slug}`}
                    />
                  </div>
                ))}

              <div className="show-more d-flex justify-content-center d-none">
                <Link
                  to="/"
                  className="w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control"
                >
                  Show More{" "}
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaPressRelease;
