import React,{useContext,useState} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import parse from "html-react-parser";
import fileEdit from "../assets/images/file-edit.svg";

import ValueCard from "./ValueCard";

import ExperienceAwaitSection from "../components/ExperienceAwaitSection";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { getPageData ,getPageData1} from "../services/dataServices";
import Helmet from "../components/Helmet";
import { MyContext } from "../Contex/LoadingContex";
import Loader from "../components/Loader";
import BannerImage from "../components/BannerImage";
import Image from "../components/Image";
function OurStory({isPreview, userId, sectionId}) {
  const pageCode = "OURSTORY";


  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
     
  const {isLoading,isSuccess}=pagesSectionData1
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);


  const ourStorySectionCode = useQuery(["pagesSectionData", pageCode,isPreview, userId, sectionId], () =>
    getPageData(pageCode,isPreview, userId, sectionId)
  );

 
  const truncateText = (text = "", length) => {
    return text?.length > +length ? text.slice(0, +length) + "..." : text;
  };

  const manageOurStorySection =
    ourStorySectionCode?.data?.data?.[0]?.section_details?.filter(
      (item) => item.section_code === "MANAGEOURSTORY"
    );

  
    let keywords=ourStorySectionCode?.data?.data?.[0]?.meta_keyword
    let slug=  ourStorySectionCode?.data?.data?.[0]?.slug
    let meta_title=ourStorySectionCode?.data?.data?.[0]?.meta_title
    let meta_description= ourStorySectionCode?.data?.data?.[0]?.meta_description

    const pageBanner= pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEOURSTORY" && item.field_label_code === "PAGEBANNER"
  )[0]?.psdd_data ?? "";

 
  // const pageBanner = manageOurStorySection?.[0]?.section_elements?.find(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )?.data;

  const pageBannerAltTag =  pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEOURSTORY" && item.field_label_code === "PAGEBANNER"
  )[0]?.img_alt_tag ?? ""
  // manageOurStorySection?.[0]?.section_elements?.find(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )?.img_alt_tag;
  const welcomeToAtmosphere = manageOurStorySection?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "WELCOMETOATMOSPHERE"
  );
  const ourPurpose = manageOurStorySection?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "OURPURPOSE"
  );
  const ourPromise = manageOurStorySection?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "OURPROMISE"
  );
  const manageOurValuesSection =
    ourStorySectionCode?.data?.data?.[0].section_details?.filter(
      (item) => item.section_code === "MANAGEOURVALUES"
    );
  //for value title

  const valueMaintitle = manageOurValuesSection?.[0]?.section_elements?.find(
    (item) => item.field_label_code === "TITLE"
  ).data;

  //data for main section
  const mainSectionIcon = welcomeToAtmosphere?.[0]?.elements?.filter(
    (item) => item.field_title_code === "CHANGEICON"
  )[0].data;
  const mainSectionSubtitle = welcomeToAtmosphere?.[0]?.elements?.filter(
    (item) => item.field_title_code === "SUBTITLE"
  )[0].data;
  const mainSectionTitle = welcomeToAtmosphere?.[0]?.elements?.filter(
    (item) => item.field_title_code === "TITLE"
  )[0].data;
  const mainSectionDescription = welcomeToAtmosphere?.[0]?.elements?.filter(
    (item) => item.field_title_code === "DESCRIPTION"
  )[0].data;

  // for purpose section data
  const purposeMaintitle = ourPurpose?.[0]?.elements?.find(
    (item) => item.field_title_code === "HEADING"
  ).data;
  const purposeHeading = ourPurpose?.[0]?.elements?.filter(
    (item) => item.field_title_code === "CARDHEADING"
  )[0].data;
  const purposeSubheading = ourPurpose?.[0]?.elements?.filter(
    (item) => item.field_title_code === "SUBHEADING"
  )[0].data;
  const purposeDescription = ourPurpose?.[0]?.elements?.filter(
    (item) => item.field_title_code === "DESCRIPTION"
  )[0].data;

  const purposeImage = ourPurpose?.[0].elements.filter(
    (item) => item.field_title_code === "FEATUREIMAGE"
  )[0].data;
  const purposeImageALtTag = ourPurpose?.[0]?.elements?.filter(
    (item) => item.field_title_code === "FEATUREIMAGE"
  )[0].img_alt_tag;
  // for promise section data
  const promiseMaintitle = ourPromise?.[0]?.sub_section_title;
  const promiseHeading = ourPromise?.[0]?.elements?.filter(
    (item) => item.field_title_code === "CARDHEADING"
  )[0].data;
  const promiseSubheading = ourPromise?.[0]?.elements?.filter(
    (item) => item.field_title_code === "SUBHEADING"
  )[0].data;
  const promiseDescription = ourPromise?.[0]?.elements?.filter(
    (item) => item.field_title_code === "DESCRIPTION"
  )[0].data;
  const promiseImage = ourPromise?.[0]?.elements?.filter(
    (item) => item.field_title_code === "FEATUREIMAGE"
  )[0].data;

  const promiseImageAltTag = ourPromise?.[0]?.elements?.filter(
    (item) => item.field_title_code === "FEATUREIMAGE"
  )[0].img_alt_tag;
  const para = `We are not just hosts but storytellers, crafting authentic, immersive, and enriching experiences that captivate your imagination. Our narrative is sewn together by stories, local anecdotes, and perfect imperfections mirroring the natural habitat our properties call home. In every stay, we kindle wanderlust, inviting you to lose yourself in our wonder-filled destinations. Welcome to a world where hospitality is not just a service but a heartfelt tale tied together with a spirit of generosity and humility.`;
 
 
  return (
    <>
    <Helmet keywords={keywords} slug={slug} metaDescription={meta_description} title={meta_title}/>
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100">
            {/* <img
              className="w-100 h-100"
              src={pageBanner}
              tiCherish
              the
              charm
              of
              thoughtfully
              curated
              distinctive
              experiences
              that
              are
              bound
              to
              take
              you
              on
              a
              memorable
              journey
              filled
              with
              inspirations
              drawn
              from
              local
              alt={pageBannerAltTag}
              onLoad={handleImageLoad}
              onError={handleError}
            /> */}
             <BannerImage
              className="w-100 h-100"
              src={pageBanner}
              title=""
              alt={pageBannerAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                OUR STORY
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Our Story</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <ExperienceAwaitSection
        image={mainSectionIcon}
        para={mainSectionDescription}
        showLearnMore={false}
        heading={mainSectionSubtitle}
        title={mainSectionTitle}
      />

      <section>
        <div className="d-none atmosphere-experience-await section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="heading-icon">
                  <img
                    src={fileEdit}
                    title="Atmosphere Living"
                    alt="Atmosphere Living"
                  />
                </div>
                <div className="small-heading my-xl-4 my-2 py-2">
                  <span className="text-uppercase">
                    WELCOME TO ATMOSPHERE HOTELS & RESORTS
                  </span>
                </div>
                <h1 className="text-uppercase georgia heading-space">
                  A New Experience Awaits
                </h1>
              </div>
              <div className="col-12">
                <div className="paragraph pt-3 text-center">
                  <p>{parse(para)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="our-values">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashed-heading position-relative">
                  <h2 className="georgia text-uppercase">
                    <span>{valueMaintitle}</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* /row */}

            <div className="row justify-content-center mt-lg-5 mt-4">
              <ValueCard valueData={manageOurValuesSection} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="our-values section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashed-heading position-relative">
                  <h2 className="georgia text-uppercase">
                    <span>{purposeMaintitle}</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* /row */}

            <div className="row mt-lg-5 mt-4 position-relative">
              <div className="col-md-7">
                <motion.div
                  key={Math.random()}
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  className="feature-left-image"
                >
                  <Image
                    className="w-100"
                    src={purposeImage}
                    alt={purposeImageALtTag}
                    title=""
                  />
                   {/* <img
                    className="w-100"
                    src={purposeImage}
                    alt={purposeImageALtTag}
                    title=""
                  /> */}
                </motion.div>
              </div>
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="right-feature-text-col position-absolute bottom-0 end-0 top-0 d-flex align-items-center"
              >
                <div className="right-feature-wrap bg-white">
                  <div className="top-head-text text-center">
                    <h3 className="georgia">
                      {truncateText(purposeHeading, 80)}
                    </h3>
                    <span>{truncateText(purposeSubheading, 50)}</span>
                  </div>
                  <div className="feature-text-para text-center position-relative">
                    <p>{truncateText(purposeDescription, 210)}</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="our-values section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashed-heading position-relative">
                  <h2 className="georgia text-uppercase">
                    <span>{truncateText(promiseMaintitle, 50)}</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* /row */}

            <div className="row mt-lg-5 mt-4 position-relative">
              <div className="col-md-7 offset-md-5">
                <motion.div
                  key={Math.random()}
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  className="feature-left-image"
                >
                  <Image
                    className="w-100"
                    src={promiseImage}
                    alt={promiseImageAltTag}
                    title=""
                  />
                  {/* <img
                    className="w-100"
                    src={promiseImage}
                    alt={promiseImageAltTag}
                    title=""
                  /> */}
                </motion.div>
              </div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="right-feature-text-col position-absolute bottom-0 start-0 top-0 d-flex align-items-center"
              >
                <div className="right-feature-wrap bg-white">
                  <div className="top-head-text text-center">
                    <h3 className="georgia">
                      {truncateText(promiseHeading, 80)}
                    </h3>
                    <span>{truncateText(promiseSubheading, 50)}</span>
                  </div>
                  <div className="feature-text-para text-center position-relative">
                    <p>{truncateText(promiseDescription, 210)}</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurStory;
