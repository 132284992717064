import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Image from "./Image";

function SubBrandsSlides({ pagesSectionData, gallerySettings,cardBg }) {
  const settings = { ...gallerySettings };

  return (
    <Slider {...settings}>
      {pagesSectionData &&
        pagesSectionData.data &&
        pagesSectionData?.data?.data?.map((sectionData) => {
          return (
            sectionData &&
            sectionData.section_details
              .filter((item) => item.section_code === "OURBRANDS")[0]
              .subSectionData.filter(
                (logoData) => logoData.field_title_code !== "UPLOADLOGO"
              )
              .map((subData, index) => {
                if (subData && subData.elements) {
                  const featureImage = subData.elements.filter(
                    (items) => items.field_title_code === "IMAGE"
                  )[0]?.data;

                  const cardHeading = subData.elements.filter(
                    (items) => items.field_title_code === "HEADING"
                  )[0]?.data;

                  const cardParagraph = subData.elements.filter(
                    (items) => items.field_title_code === "CONTENT"
                  )[0]?.data;

                  const cardUrl = subData.elements.filter(
                    (items) => items.field_title_code === "DISCOVERBUTTON"
                  )[0]?.data;

                  if (
                    featureImage !== undefined &&
                    cardHeading !== undefined &&
                    cardParagraph !== undefined &&
                    cardUrl !== undefined
                  ) {
                    return (
                      <div className="feature-card position-relative overflow-hidden rounded-4">
                        <div className="card-image overflow-hidden">
                        <Image
                            className="w-100"
                            src={featureImage}
                            alt="Our Gallery"
                            title="Our Gallery"
                          />
                          {/* <img
                            className="w-100"
                            src={featureImage}
                            alt="Our Gallery"
                            title="Our Gallery"
                          /> */}
                        </div>
                        <div
                          className={`sub-card z-1 position-absolute text-center overflow-hidden smooth cardBg${index+1}`}
                        >
                          <div className="sub-card-title smooth d-flex justify-content-center align-items-center text-white georgia text-uppercase">
                            <h4 className="m-0">{cardHeading}</h4>
                          </div>
                          <div className="sub-card-para smooth">
                          {cardParagraph?(
                                  <p>{parse(cardParagraph)}</p>
                            ):(<p></p>)}
                            <div className="card-link mt-md-4 mt-2 mb-3 smooth position-relative">
                              <Link to={cardUrl}>Discover</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
                return null; // Skip rendering if the conditions are not met
              })
          );
        })}
    </Slider>
  );
}

export default SubBrandsSlides;
