import React from 'react'
import parse from 'html-react-parser'
import pressReleases from '../assets/images/awards-badge.svg'
import pdfIcon from '../assets/images/pdf.svg'
import downloadNow from '../assets/images/download.svg'
import CollateralCards from './CollateralCards'
import { getAllCollateral } from '../../src/services/dataServices'
import { useQuery } from 'react-query';
import justVegKidsMenu from '../assets/collaterals/kanifushi/AKM-JUST-VEG-KIDS-MENU.pdf'
import sunsetDinnerMenu from '../assets/collaterals/kanifushi/AKM-SUNSET-DINNER-MENU.pdf'
import teppanyakiGrillMenu from '../assets/collaterals/kanifushi/AKM-TEPPANYAKI-GRILL-MENU-AT-PIER-SIX-SEP-2020.pdf'
import atmosphereKanifushiBrochure from '../assets/collaterals/kanifushi/Atmosphere-Kanifushi-Brochure-OCT-2023-WEB.pdf'
import atmosphereKanifushiPlan from '../assets/collaterals/kanifushi/Atmosphere-Kanifushi-The-Kanifushi-Plan-2023.pdf'
import atmosphereVaruBrochure from '../assets/collaterals/varu/VARU-by-Atmosphere-Brochure-OCT-2023.pdf'
import varuRenewalOfVows from '../assets/collaterals/varu/VARU-by-Atmosphere-Renewal-of-Vows-2023.pdf'
import varuCharcoalMenu from '../assets/collaterals/varu/VARU-CHARCOAL-MENU-NOV-2023.pdf'
import varuCigarMenu from '../assets/collaterals/varu/VARU-CIGAR-MENU-2023-JAN.pdf'
import varuExcursionSchedule from '../assets/collaterals/varu/Varu-Excursion-Schedule.pdf'
import varuNuMenu from '../assets/collaterals/varu/VARU-NU-MENU-NOV-2023.pdf'
import varuVaruPlan from '../assets/collaterals/varu/VARU-PLAN-2022-2023.pdf'

function MediaCollaterals({ manageMedia }) {

  // const kanifushTitle = "Atmosphere - Kanifushi"
  // const varuTitle = "By Atmosphere - Varu"
  const allCollateral = useQuery("getAllCollateral", getAllCollateral);
  const dataCollateral = allCollateral?.data?.data;





  /*******************************MEDIA COLLATERALS START********************************* */
  let collateralData = manageMedia?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "COLLATERALS"
  );
  const collateralIcon =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "CHANGEICON"
    )[0]?.data ?? null;

  const collateralTitle =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "TITLE"
    )[0]?.data ?? null;

  const collateralHeading =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;

  const collateralDescription =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";

  /*******************************MEDIA COLLATERALS END********************************* */
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
              <img src={collateralIcon} title='Atmosphere Living' />
            </div>
            <div className='small-heading'>
              <span className='text-uppercase'>{collateralTitle}</span>
            </div>
            <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{collateralHeading}</h1>
            <div className='media-para m-auto pt-3 text-center'>
              <p>
                {parse(collateralDescription)}
              </p>
            </div>
          </div>
          <div className='collaterals-cards mt-lg-5 mt-4'>
            <div className='row gy-4'>
              <div className='col-12'>
                {/* <h4 className='text-uppercase georgia letter-spacing-2'>{menuTitle}</h4> */}

                {dataCollateral?.map((item) => (
                  <div key={item?.head_id} className="mb-5">

                    <h4 className='text-uppercase georgia letter-spacing-2 mb-3'>
                      {item?.collateral_name} </h4>

                    <div className='row gy-4'>
                      {item?.data?.map((collateralItem) => (
                        <div className='col-lg-6'>
                          <CollateralCards
                            key={collateralItem.id}
                            collateralTitle={collateralItem.title}
                            collateralPara={collateralItem.description}
                            docTypeIcon={pdfIcon}
                            docDownloadIcon={downloadNow}
                            downloadCollateral={collateralItem.pdf_url}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default MediaCollaterals