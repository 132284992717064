import React from 'react'

import parse from 'html-react-parser'

import { Link } from 'react-router-dom'
import Image from './Image'
function FeatureCard({ index, cardFeatureImage, cardBg, cardHeading, cardParagraph = '', cardUrl, cardLinkName,featureImageAltTag='' }) {


    return (
        <>
            <div className='col-xl-4 col-md-6' key={index}>
                <div className='feature-card position-relative overflow-hidden rounded-4'>
                    <div className='card-image overflow-hidden'>
                    <Image className='w-100' src={cardFeatureImage} alt={featureImageAltTag} title='' />

                        {/* <img className='w-100' src={cardFeatureImage} alt={featureImageAltTag} title='' /> */}
                    </div>
                    <div className={`sub-card z-1 position-absolute text-center overflow-hidden smooth ${cardBg}`}>
                        <div className={`sub-card-title smooth d-flex justify-content-center align-items-center text-white georgia text-uppercase`}>
                            {cardHeading}
                        </div>
                        <div className='sub-card-para smooth'>
                            {cardParagraph?(
                                  <p>{parse(cardParagraph)}</p>
                            ):(<p></p>)}
                          
                            {/* <hr className={`${cardBorder }`} /> */}
                            <div className='card-link mt-md-4 mt-2 mb-3 smooth position-relative'>
                                <Link to={cardUrl}>{cardLinkName}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeatureCard