import React from "react";


function SustainFeatureBox({ pagesSectionData }) {



  return (
    <>
      {pagesSectionData &&
        pagesSectionData.data &&
        pagesSectionData?.data?.data?.map((sectionData, index) => {
          return (
            sectionData &&
            sectionData.section_details
              .filter(
                (item) => item.section_code === "OURGRENINITIATIVES"
              )[0]
              .subSectionData.map((subData) => {
                return (
                  <div key={index} className="col-xl-3 col-lg-4 col-sm-6" >
                    <div className="sustain-card pt-xl-5 pt-4 pb-3 px-2">
                      <div
                        className={
                          "card-image d-flex align-items-center justify-content-center m-auto"
                        }
                      >
                        <img
                          src={
                            subData &&
                            (subData.elements.filter(
                              (items) => items.field_title_code === "CHANGEICON"
                            )[0]?.data ??
                              null)
                          }
                          alt={subData &&
                            subData.elements.filter(
                              (items) => items.field_title_code === "HEADING"
                            )[0]?.data}
                          title={subData &&
                            subData.elements.filter(
                              (items) => items.field_title_code === "HEADING"
                            )[0]?.data}
                        />
                      </div>
                      <div className="sustain-text-box px-xl-2 mt-xl-4 mt-3 rounded-4 ms-auto me-auto text-center">
                        <h5 className="georgia mb-3">
                          {subData &&
                            subData.elements.filter(
                              (items) => items.field_title_code === "HEADING"
                            )[0]?.data}
                        </h5>
                        <p>
                          {subData &&
                            subData.elements.filter(
                              (items) => items.field_title_code === "CONTENT"
                            )[0]?.data}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
          );
        })}
    </>
  );
}

export default SustainFeatureBox;
