export const truncateAtLastWord = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }

    const lastSpaceIndex = text.lastIndexOf(' ', maxLength);

    if (lastSpaceIndex !== -1) {
        return text.substring(0, lastSpaceIndex) + '.....';
    }

    // If no space is found, simply truncate at maxLength and add "..."
    return text.substring(0, maxLength) + '.....';
};