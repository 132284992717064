import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import parse from "html-react-parser";
import * as querydata from "../services/dataServices";
import { useQuery } from "react-query";
import { truncateAtLastWord } from '../commonHelper/CommonHelper';
import Image from '../components/Image';
function PressReleaseCard({ cardImage, cardTitle, cardDate, cardDescription, cardLink, cardUrl,cardImageAltTag }) {

  function truncate(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);
    // const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    // if (lastSpaceIndex !== -1) {
    //   return truncatedText.slice(0, lastSpaceIndex);
    // }

    // If there is no space in the truncated part, just return the original truncated text
    return truncatedText;
  }
  const getWordCountValue = useQuery(
    ["AspectCount", "press_release_wordcount_for_card"],
    () => querydata.getAspectRatio("press_release_wordcount_for_card")
  );


  const wordCount = getWordCountValue && getWordCountValue.data && getWordCountValue.data.data.setting_value;

  return (
    <div className="card border-0">
      <div className='card-image'>
      <Image src={cardImage} alt={cardImageAltTag} title={''} />
      {/* <img src={cardImage} alt={cardImageAltTag} title={''} /> */}
      </div>
      <div className="card-body">
        <h4 className="georgia mb-0">
          {/* {cardTitle} */}
          {cardTitle.length > wordCount ? (
            <>

              {parse(truncate(cardTitle, wordCount))}
              {"...."}
            </>
          ) : (
            parse(cardTitle)

          )}
        </h4>
        <div className='press-date my-lg-4 my-3'>
          <span>{cardDate}</span>
        </div>
        <div className="card-text">
          {cardDescription.length > 180 ? (
            <>
              {/* {removeHtmlTags(cardDescription).substring(0, 100)}{'....'} */}

              {parse(truncateAtLastWord(cardDescription, 180))}
              {/* {"...."} */}
            </>
          ) : (

            parse(cardDescription)

          )}

        </div>
        <div className='read-more text-end'>
          <Link className='text-decoration-none text-uppercase' to={cardUrl}>{cardLink} <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
        </div>
      </div>
    </div>
  )
}

export default PressReleaseCard