import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import TabResortsView from "./TabResortsView";

function TabResortSlider(resortSlideData) {
    const resortSliderSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        centerMode:true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow:  1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              centerMode: false,
              slidesToShow:  1,
            },
          },
        ],
      };
  return (
    <>
        <Slider {...resortSliderSettings}>
            {resortSlideData.resortSlideData.map((tabResort,index) =>(
                 <TabResortsView
                 key={index}
                 resortImg={
                   tabResort?.elements?.find(
                     (item) => item?.field_title_code === "RESORTIMAGE"
                   )?.data
                 }
                 resortImgAltTag={tabResort?.elements?.find(
                     (item) => item?.field_title_code === "RESORTIMAGE"
                   )?.img_alt_tag}
                 resortName={
                   tabResort?.elements?.find(
                     (item) => item?.field_title_code === "RESORTNAME"
                   )?.data
                 }
                 resortLocation={
                   tabResort?.elements?.find(
                     (item) =>
                       item?.field_title_code === "RESORTLOCATION"
                   )?.data
                 }
                 resortLink={
                   tabResort?.elements?.find(
                     (item) =>
                       item?.field_title_code === "RESORTLOCATION"
                   )?.data
                 }
                 resrotVisitName={tabResort.resrotVisitName}
                 linkTarget={
                   tabResort?.elements?.find(
                     (item) =>
                       item?.field_title_code === "VISITRESORTBUTTON"
                   )?.data
                 }
               /> 
             )) }
            </Slider>
    </>
  )
}

export default TabResortSlider