import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPaperPlane,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import FooterLogo from "../assets/images/footer-logo.svg";
import AtmosphereCore from "../assets/images/atmosphere-core-logo.svg";
import TwitterX from "../assets/images/twitter-x.svg";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { newsLetter, getPageData, getPagesSlug } from '../services/dataServices'
import { Formik, useFormik } from 'formik';
export const Validation = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});
function Footer() {
  const initialValues = {
    email: ''
  }
  const [loading, setLoading] = useState(false);
  const [displayNewsLetter, setdisplayNewsLetter] = useState(true);
  const [response, setResponse] = useState(null);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Validation,
    onSubmit: (values, action) => {
      setLoading(true)
      setNewsLetter()
    }
  })

  const setNewsLetter = async () => {
    const data = await newsLetter(values.email)
    setResponse(data)

    setLoading(false)
    setdisplayNewsLetter(false)

  }


  const {
    values,
    handleSubmit,
    errors,
    touched,
    handleChange

  } = formik;
  const pageCode = "CONTACTUS";

  const pagesSectionData = useQuery(["pagesSectionData", pageCode], () =>
    getPageData(pageCode)
  );
  const manageContactUs =
    pagesSectionData &&
    pagesSectionData?.data?.data[0]?.section_details?.filter(
      (item) => item.section_code === "MANAGECONTACTUS"
    );
  let contactDetail = manageContactUs?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "CONTACTDETAILS"
  );
  const phoneNoData =
    contactDetail?.[0]?.elements?.filter(
      (item) => item.field_title_code === "PHONENO"
    )[0]?.data ?? null;

  const emailIdData =
    contactDetail?.[0]?.elements?.filter(
      (item) => item.field_title_code === "EMAILID"
    )[0]?.data ?? null;
  const getAllRoutes = useQuery("getPagesSlug", getPagesSlug);

  const slugOfTermsConditon = getAllRoutes?.data?.data
    .filter(item => item.page_code === "TERMCONDITION")[0]?.slug ?? null;

  const slugOfPrivacyPolicy = getAllRoutes?.data?.data
    .filter(item => item.page_code === "PRIVACYPOLICY")[0]?.slug ?? null;

  const slugOfContactUs = getAllRoutes?.data?.data
    .filter(item => item.page_code === "CONTACTUS")[0]?.slug ?? null;

  const slugOfMedia = getAllRoutes?.data?.data
    .filter(item => item.page_code === "MEDIA")[0]?.slug ?? null;


    let followUsData = manageContactUs?.[0]?.subSectionData?.filter(
      (item) => item.sub_section_title_code === "FOLLOWUS"
    );
  
    const contactUsHeading =
      followUsData?.[0]?.elements?.filter(
        (item) => item.field_title_code === "HEADING"
      )[0]?.data ?? "";
  
    const facebookUrl =
      followUsData?.[0]?.elements?.filter(
        (item) => item.field_title_code === "FACEBOOK"
      )[0]?.data ?? "";
  
    const linkedInUrl =
      followUsData?.[0]?.elements?.filter(
        (item) => item.field_title_code === "LINKEDIN"
      )[0]?.data ?? "";
  
    const instaGramUrl =
      followUsData?.[0]?.elements?.filter(
        (item) => item.field_title_code === "INSTAGRAM"
      )[0]?.data ?? "";

  return (
    <>
      <footer>
        <div className="footer-newsletter py-4">
          <div className="container">
            <div className="row my-2">
              <div className="col-12">
                <div className="d-flex align-items-center flex-md-nowrap flex-wrap justify-content-center">
                  <h6 className="text-uppercase georgia m-0 text-white">
                    SIGN UP FOR OUR NEWSLETTER
                  </h6>
                  <form action="#" method="get">
                    {/* <div className='position-relative d-flex'>

{
    displayNewsLetter ? <>
        <Form.Control type="email" onChange={handleChange} name="email" value={values.email} className='bg-transparent text-white shadow-none' placeholder="Enter your email address..." />

        <Button onClick={handleSubmit} className='d-flex justify-content-center align-items-center text-uppercase' id="button-addon2"><FaPaperPlane className='icon-font' /> {loading ? <div class="spinner-border" role="status"></div> : 'Send'}</Button>
        {errors.email && touched.email ? (
            <p className="form-error text-danger">
                {errors.email}
            </p>
        ) : ''}
    </> : <p className='thank-message m-0 text-white text-capitalize'><span>thanks for subscribe</span></p>

}
</div> */}
                    <div className="position-relative d-flex">
                      {displayNewsLetter ? <>
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          className="bg-transparent text-white shadow-none"
                          placeholder="Enter your email address..."
                        />
                        <Button
                          onClick={handleSubmit}
                          className="d-flex justify-content-center align-items-center text-uppercase"
                          id="button-addon2"
                        >
                          <FontAwesomeIcon
                            icon={faPaperPlane}
                            className="icon-font"
                          />{" "}
                          Send
                        </Button>
                        {errors.email && touched.email ? (
                          <p className="form-error text-danger">
                            {errors.email}
                          </p>
                        ) : ''}</> :
                        <p className='thank-message m-0 text-white text-capitalize'><span>Thank you for subscribing to our newsletter.</span></p>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /footer-newsletter */}
        <div className="mein-footer">
          <div className="footer-wrap">
            <div className="row align-items-center">
              <div className="col-xxl-4 col-xl-3">
                <div className="footer-logo">
                  <Image src={FooterLogo} alt="Atmosphere" title="Atmosphere" />
                </div>
              </div>
              {/* /col-lg-3 */}
              <div className="col-xxl-4 col-xl-5 col-md-6">
                <div className="footer-col d-flex justify-content-center no-border mt-2 mt-md-0 mb-3 mb-md-0">
                  <div className="footer-links text-center">
                    <Link
                      to={`/${slugOfTermsConditon}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <span>Terms of Use</span>
                    </Link>
                    <Link
                      to={`/${slugOfPrivacyPolicy}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <span>Privacy Policy</span>
                    </Link>
                    {/* <Link to="/coming-soon" onClick={()=>window.scrollTo(0,0)}><span>GDPR Compliance</span></Link> */}
                    <Link to={`/${slugOfMedia}`}>
                      <span>Media Center</span>
                    </Link>
                    <Link to={`/${slugOfContactUs}`}>
                      <span>Contact</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* /col-lg-3 */}
              <div className="col-xxl-4 col-xl-4 col-md-6">
                <div className="footer-col d-flex justify-content-end">
                  <div className="location d-flex align-items-center">
                  {phoneNoData? 
                    <div className="phone-footer text-center email-footer">
                      <span className="icon-phone">
                        <FontAwesomeIcon icon={faPhone} className="icon-font" />
                      </span>
                      <a href={`tel:${phoneNoData}`}>{phoneNoData}</a>
                    </div>:<div className="phone-footer text-center email-footer"></div>
                  }
                    
                   {emailIdData? 
                    <div className="phone-footer ms-lg-5 ms-4 text-center email-footer">
                      <span className="icon-phone">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="icon-font"
                        />
                      </span>{" "}
                      <Link to={`mailto:${emailIdData}`}>
                        {emailIdData}
                      </Link>
                    </div>:""
                   }                    
                  </div>
                </div>
              </div>
              {/* /col-lg-3 */}
            </div>
          </div>
          {/* /footer-wrap */}
          <div className="bottom-footer d-flex justify-content-between align-items-center">
            <div className="copyright">
              <span>
                &copy; Atmosphere Hotels & Resorts 2023. All Rights Reserved.{" "}
              </span>
              Powered by
              <a className="text-underline text-white" href='https://www.digitechsoft.com/' target='_blank'> Digitech.</a>
              {/* <span>Powered by <Link to='https://www.digitechsoft.com/' target='_blank' className='text-decoration-none'>Digitech</Link>.</span> */}
            </div>
            <div className="footer-social">
            {facebookUrl? 
              <Link to={`${facebookUrl}`} target="_blank">
                <FontAwesomeIcon icon={faFacebookF} className="icon-font" />
              </Link>:""
            }
             
              <Link className="d-none" href="/">
                <img src={TwitterX} alt="Twitter" title="Twitter" />
              </Link>
              {linkedInUrl? 
              <Link
                to={`${linkedInUrl}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className="icon-font" />
              </Link>:""}
             {instaGramUrl?              
              <Link
                to={`${instaGramUrl}}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} className="icon-font" />
              </Link>:""
             }
            </div>
            <div className="atmosphere-core-logo">
              <Link to="https://www.atmospherecore.com/" target="_blank">
                <Image
                  src={AtmosphereCore}
                  alt="Atmosphere"
                  title="Atmosphere"
                />
              </Link>
            </div>
          </div>
        </div>
        {/* /main-footer */}
      </footer>
    </>
  );
}

export default Footer;
