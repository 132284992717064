import React from "react";

import parse from "html-react-parser";

import { Link } from "react-router-dom";

import { useQuery } from "react-query";
import { getPageData } from "../services/dataServices";
function ExperienceAwait({
  isPreview,
  userId,
  sectionId,
  slugOfOurStory = "",
}) {
  const pageCode = "HOME";

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );

  return (
    <>
      <section>
        <div className="atmosphere-experience-await section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                {pagesSectionData &&
                  pagesSectionData.data &&
                  pagesSectionData?.data?.data?.map((sectionData, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="heading-icon mb-xl-4 mb-2 pt-2 pb-2"
                        >
                          <img
                            src={
                              sectionData &&
                              sectionData.section_details
                                .filter(
                                  (item) =>
                                    item.section_code === "EXPERIENCEAWAITS"
                                )[0]
                                .section_elements.filter(
                                  (item) =>
                                    item.field_label_code === "CHANGEICON"
                                )[0].data
                            }
                            title="Atmosphere Living"
                            
                          />
                        </div>
                        <div className="small-heading">
                          <span className="text-uppercase">
                            {sectionData &&
                              sectionData.section_details
                                .filter(
                                  (item) =>
                                    item.section_code === "EXPERIENCEAWAITS"
                                )[0]
                                .section_elements.filter(
                                  (item) =>
                                    item.field_label_code === "WELCOMEMESSAGE"
                                )[0].data}
                          </span>
                        </div>
                        <h1 className="text-uppercase georgia heading-space mt-xl-4 mt-2">
                          {sectionData &&
                            sectionData.section_details
                              .filter(
                                (item) =>
                                  item.section_code === "EXPERIENCEAWAITS"
                              )[0]
                              .section_elements.filter(
                                (item) => item.field_label_code === "HEADING"
                              )[0].data}
                        </h1>

                        <div className="paragraph pt-3 text-center">
                          <p>
                            {parse(
                              (sectionData &&
                                sectionData.section_details
                                  .filter(
                                    (item) =>
                                      item.section_code === "EXPERIENCEAWAITS"
                                  )[0]
                                  .section_elements.filter(
                                    (item) => item.field_label_code === "BODY"
                                  )[0].data) ||
                                ""
                            )}
                          </p>
                        </div>

                        <div className="action-btn mt-lg-5 mt-4 text-center">
                          <Link
                            to={`/${slugOfOurStory}`}
                            className="btn theme-btn fw-medium text-white text-uppercase border-theme"
                          >
                            {sectionData &&
                              sectionData.section_details
                                .filter(
                                  (item) =>
                                    item.section_code === "EXPERIENCEAWAITS"
                                )[0]
                                .section_elements.filter(
                                  (item) =>
                                    item.field_label_code === "OURSTORYBUTTON"
                                )[0].data}
                          </Link>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ExperienceAwait;
