import React,{useContext,useState} from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/media-cover.jpg'
import MediaTabs from './MediaTabs.js'
import { Link } from 'react-router-dom';
import {useQuery } from "react-query";
import { getPageData,getPageData1 } from "../services/dataServices";
import Helmet from '../components/Helmet.js';
import { MyContext } from "../Contex/LoadingContex"
import Loader from '../components/Loader.js';
import BannerImage from '../components/BannerImage.js';
function Media({isPreview, userId, sectionId,slugOfMedia}) {
    const pageCode = "MEDIA";

    const pagesSectionData1 = useQuery(
      ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
      () => getPageData1(pageCode, isPreview, userId, sectionId)
    );
       
    const {isLoading,isSuccess}=pagesSectionData1
    const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  
 

    const mediaPagesSectionData = useQuery(["pagesSectionData", pageCode,isPreview, userId, sectionId], () =>
      getPageData(pageCode,isPreview, userId, sectionId)
    );


  
  /***************BANNER  START**************** */
    const manageMedia =
    mediaPagesSectionData &&
    mediaPagesSectionData?.data?.data[0]?.section_details?.filter(
      (item) => item.section_code === "MANAGEMEDIA"
    );
  
  const mediaBannerImage = pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEMEDIA" && item.field_label_code === "PAGEBANNER"
)[0]?.psdd_data ?? "";
  // manageMedia?.[0]?.section_elements?.filter(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )[0]?.data ?? null;

  const mediaBannerImageAltTag =pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEMEDIA" && item.field_label_code === "PAGEBANNER"
)[0]?.img_alt_tag ?? "";
  // manageMedia?.[0]?.section_elements?.filter(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )[0]?.img_alt_tag ?? null;
  
/***************BANNER  END**************** */

const keywords = mediaPagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = mediaPagesSectionData?.data?.data[0]?.slug;
  const meta_title = mediaPagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = mediaPagesSectionData?.data?.data[0]?.meta_description;

  
  return (
    <>
     <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    {/* <img className='w-100 h-100' 
                    src={mediaBannerImage} title=''
                    onLoad={handleImageLoad}
             onError={handleError}
                     alt={mediaBannerImageAltTag} /> */}
                     <BannerImage
              className="w-100 h-100"
              src={mediaBannerImage}
              title=""
              alt={mediaBannerImageAltTag}
              isDataIsLoading={isLoading}
            />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Media</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Media</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='sub-brands-tabs media-center-tabs section-padding'>
                <MediaTabs manageMedia={manageMedia} slugOfMedia={slugOfMedia}/>
            </div>
        </section>

    </>
  )
}

export default Media