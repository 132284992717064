import React from "react";
import { motion } from "framer-motion";
import Image from "../components/Image";

function Card({ imageUrl, smalltitle, title, content, index,imageUrlAltTag }) {
  const animations = [
    { initial: { opacity: 0.2, y: -100 }, whileInView: { opacity: 1, y: 0 } },
    { initial: { opacity: 0.2, y: 100 }, whileInView: { opacity: 1, y: 0 } },
    { initial: { opacity: 0.2, y: -100 }, whileInView: { opacity: 1, y: 0 } },
  ];


  const animationVariants = animations[index % animations.length];
  return (
    <motion.div
      key={Math.random()}
      // initial={{ opacity: 0, x: 100 }}
      // whileInView={{ opacity: 1, x: 0 }}
      initial={animationVariants.initial}
      whileInView={animationVariants.whileInView}
      transition={{ duration: 1 }}
      className="col-lg-4 col-md-6"
    >
      <div className="value-card position-relative h-100">
        <div className="value-card-img h-100">
        <Image className="w-100" src={imageUrl} alt={imageUrlAltTag} />
        {/* <img className="w-100" src={imageUrl} alt={imageUrlAltTag} /> */}
        </div>
        <div className="value-card-text position-absolute start-0 top-0 w-100">
          <div className="value-card-title text-uppercase">
            <span className="text-white georgia">{smalltitle}</span>
            <h2 className="georgia text-white">{title}</h2>
          </div>
          <p>{content}</p>
        </div>
      </div>
    </motion.div>
  );
}

const ValueCard = ({ valueData }) => {
  const subSectionValues = valueData?.[0]?.subSectionData;



  return (
    <>
      {subSectionValues?.map((card, index) => (
        <Card
          key={index} // Don't forget to add a unique key for each card when mapping over an array field_title_code
          index={index}
          title={
            card.elements.filter(
              (items) => items.field_title_code === "VALUETITLE"
            )[0]?.data ?? null
          }
          smalltitle={"WE ARE"}
          content={
            card &&
            (card.elements.filter(
              (items) => items.field_title_code === "DESCRIPTION"
            )[0]?.data ??
              null)
          }
          imageUrl={
            card &&
            (card.elements.filter(
              (items) => items.field_title_code === "VALUEIMAGE"
            )[0]?.data ??
              null)
          }
          imageUrlAltTag={
            card &&
            (card.elements.filter(
              (items) => items.field_title_code === "VALUEIMAGE"
            )[0]?.img_alt_tag ??
              null)
          }
        />
      ))}
    </>
  );
};

export default ValueCard;
