import React from "react";
import { Helmet as Hel } from "react-helmet";
import { getCanonicalUrl } from "../services/dataServices";
import { useQuery } from "react-query";
function Helmet({ title, metaDescription, slug, keywords }) {
  const websiteUrl = useQuery(
    ["canonical_url", "canonical_url_ahr"],
    () => getCanonicalUrl("canonical_url_ahr"));
  const baseUrl = websiteUrl?.data?.data?.setting_value;
  const baseCanonicalUrl = baseUrl;

  const canonicalUrl = slug ? `${baseCanonicalUrl}/${slug}` : baseCanonicalUrl;

  return (
    <div>
      <Hel>
        {
          title ? <title>{title}</title> : (
            <title>Atmosphere H&R</title>
          )

        }
        {/* <link rel="canonical" href={slug} /> */}
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonicalUrl} />
        {/* <meta name="title" content={title} /> */}

      </Hel>
    </div>
  );
}

// slug: "test-offer-121"
// keywords: "hello keywords"
// meta_title: "hello meta title"
// meta_description: "hello meta description"

export default Helmet;
