import React from 'react'
// import image from "../assets/images/ahr-logo.svg"

const Loader = () => {

  return (
<div>
    <section>
        <div class="logo-fade-animation">
            <div class="logo-overlay-wrapper">
                <div class="logo-wrapper">
                   
                    <span class="overlay white-overlay"></span>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Loader