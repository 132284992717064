import React,{useContext,useState} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import OffersTabs from "./OffersTabs";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getPageData ,getPageData1} from "../services/dataServices";
import Helmet from "../components/Helmet";
import { MyContext } from "../Contex/LoadingContex"
import Loader from "../components/Loader";
import BannerImage from "../components/BannerImage";
function Offers({isPreview, userId, sectionId,slugOfOffers}) {
  const pageCode = "OFFERS";
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
     
  const {isLoading,isSuccess}=pagesSectionData1
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

  const bannerImage= pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEOFFER" && item.field_label_code === "PAGEBANNER"
    )[0]?.psdd_data ?? "";
 const bannerImageAltTag= pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEOFFER" && item.field_label_code === "PAGEBANNER"
    )[0]?.img_alt_tag ?? "";
  
  
    const offerPageData = useQuery(["pagesSectionData", pageCode,isPreview, userId, sectionId], () =>
    getPageData(pageCode,isPreview, userId, sectionId)
  );

 
   

  const keywords = offerPageData?.data?.data[0]?.meta_keyword;
  const slug = offerPageData?.data?.data[0]?.slug;
  const meta_title = offerPageData?.data?.data[0]?.meta_title;
  const meta_description = offerPageData?.data?.data[0]?.meta_description;
 
  return (
    <>
     <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>

        <div className='inner-cover header-space position-relative'>
          <div className='our-story position-absolute start-0 top-0 h-100'>
            {/* <img className='w-100 h-100'
             src={bannerImage}
             onLoad={handleImageLoad}
             onError={handleError}
             title='' 
             alt={bannerImageAltTag} /> */}
              <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />

          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                OFFERS
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Our Offers</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <section>
        <div className="sub-brands-tabs offers-tabs section-padding">
          <OffersTabs slugOfOffers={slugOfOffers} />
        </div>
      </section>
    </>
  );
}

export default Offers;
