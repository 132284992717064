import React from 'react'
import HotelsResortsSlides from './HotelsResortsSlides'
import { useQuery } from "react-query";
import { getPageData } from "../services/dataServices";
function HotelsResorts({isPreview, userId, sectionId}) {
  const pageCode = "HOME";


  const pagesSectionData = useQuery(["pagesSectionData", pageCode,isPreview,userId,sectionId], () =>
    getPageData(pageCode,isPreview, userId, sectionId)
  );
  const hotelsData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "OURHOTELSANDRESORTS"
      )[0] ?? null
  );
  const MainBanner =
    hotelsData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === 'MAINTITLE'
    )[0]?.data ?? null;

  return (
    <>
      <section>
        <div className='hotels-resorts section-padding pt-0'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='hotel-heading-wrap d-flex align-items-center justify-content-between'>
                  <h1 className='text-uppercase georgia heading-space mb-0'>{MainBanner}</h1>
                  {/* <Link to='' className='text-dark text-decoration-none view-all text-uppercase d-md-flex d-none' title='Course Link'>see ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link> */}
                </div>
              </div>
            </div>
          </div>
          {/* /container-fluid */}
          <HotelsResortsSlides />
        </div>
      </section>
    </>
  )
}

export default HotelsResorts