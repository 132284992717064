import React,{useContext,useState} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import SubBrandTabs from "./SubBrandTabs.js";
import DiscoverItems from "./DiscoverItems.js";
import discoverOne from "../assets/images/discover-one.jpg";
import discoverTwo from "../assets/images/discover-two.jpg";
import discoverThree from "../assets/images/discover-three.jpg";
import discoverIconOne from "../assets/images/spoon-fork.svg";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getPageData ,getPageData1} from "../services/dataServices";
import ExperienceAwaitSection from "../components/ExperienceAwaitSection";
import Helmet from '../components/Helmet.js';
import { MyContext } from "../Contex/LoadingContex"
import Loader from "../components/Loader.js";
import BannerImage from "../components/BannerImage.js";



function OurSubBrandsPage({isPreview, userId, sectionId}) {


  const paraOne =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry...";
  const cardTitleOne = "Bedroom";

  const pageCode = "OURSUBBRANDS";
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
     
  const {isLoading,isSuccess}=pagesSectionData1
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

  const mainBanner= pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEABOUT" && item.field_label_code === "PAGEBANNER"
    )[0]?.psdd_data ?? "";


  const ourSubBrandData = useQuery(["pagesSectionData", pageCode,isPreview, userId, sectionId], () =>
    getPageData(pageCode,isPreview, userId, sectionId)
  );

 
 

  // if (!ourSubBrandData.data) {
  //   return <div>Loading...</div>;
  // }

  // const manageAboutSectionData =
  //   ourSubBrandData?.data?.data[0]?.section_details?.filter(
  //     (item) => item.section_code === "MANAGEABOUT"
  //   );
    // const mainBanner = manageAboutSectionData?.[0]?.section_elements?.filter(
    //   (item) => item.field_label_code === "PAGEBANNER"
    // )[0]?.data;
  const manageSubBrandData =
    ourSubBrandData?.data?.data[0]?.section_details?.filter(
      (item) => item.section_code === "MANAGESUBBRANDS"
    )?.[0];


  const mainSectionIcon = pagesSectionData1?.data?.sectionData?.filter((item) =>  item.section_code === "MANAGEABOUT" && item.field_label_code === "CHANGEICON"
)[0]?.psdd_data ?? "";

  // manageAboutSectionData?.[0]?.section_elements?.filter(
  //   (item) => item.field_label_code === "CHANGEICON"
  // )[0]?.data;

 
  const mainBannerImgAlt = pagesSectionData1?.data?.sectionData?.filter((item) =>  item.section_code === "MANAGEABOUT" && item.field_label_code === "PAGEBANNER"
)[0]?.img_alt_tag ?? "";
  // manageAboutSectionData?.[0]?.section_elements?.filter(
  //   (item) => item.field_label_code === "PAGEBANNER"
  // )[0]?.img_alt_tag;
  const mainSectionTitle = pagesSectionData1?.data?.sectionData?.filter((item) =>  item.section_code === "MANAGEABOUT" && item.field_label_code === "TITLE"
)[0]?.psdd_data ?? "";
  // manageAboutSectionData?.[0]?.section_elements?.filter(
  //   (item) => item.field_label_code === "TITLE"
  // )[0]?.data;

  const mainSectionDescription = pagesSectionData1?.data?.sectionData?.filter((item) =>  item.section_code === "MANAGEABOUT" && item.field_label_code === "DESCRIPTION"
)[0]?.psdd_data ?? "";
    // manageAboutSectionData?.[0]?.section_elements?.filter(
    //   (item) => item.field_label_code === "DESCRIPTION"
    // )[0]?.data;
    const keywords = ourSubBrandData?.data?.data[0]?.meta_keyword;
    const slug = ourSubBrandData?.data?.data[0]?.slug;
    const meta_title = ourSubBrandData?.data?.data[0]?.meta_title;
    const meta_description = ourSubBrandData?.data?.data[0]?.meta_description;

 
   
    
  return (
    <>
     <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100">
            {/* <img
              className="w-100 h-100"
              src={mainBanner}
              title=""
              onLoad={handleImageLoad}
              onError={handleError}
              alt={mainBannerImgAlt}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={mainBanner}
              title=""
              alt={mainBannerImgAlt}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Our Sub Brands
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Our Sub Brands</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <ExperienceAwaitSection
        image={mainSectionIcon}
        para={mainSectionDescription}
        showLearnMore={false}
        heading={mainSectionTitle}
        showHeading={false}
      />

      <section>
        <div className="sub-brands-tabs section-padding pt-0">
          <SubBrandTabs data={manageSubBrandData} />
        </div>
      </section>

      <section className="d-none">
        <div className="atmosphere-discover section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="dashed-heading position-relative">
                  <h2 className="georgia text-uppercase">
                    <span>Discover</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row gx-0 mt-lg-5 mt-4">
              <div className="col-lg-8">
                <div className="discover-left">
                  <DiscoverItems
                    cardDiscoverImage={discoverOne}
                    coverIcon={discoverIconOne}
                    cardTitle={cardTitleOne}
                    cardParagraph={paraOne}
                  />
                  <DiscoverItems
                    cardDiscoverImage={discoverTwo}
                    coverIcon={discoverIconOne}
                    cardTitle={cardTitleOne}
                    cardParagraph={paraOne}
                  />
                  <DiscoverItems
                    cardDiscoverImage={discoverThree}
                    coverIcon={discoverIconOne}
                    cardTitle={cardTitleOne}
                    cardParagraph={paraOne}
                  />
                </div>
              </div>
              {/* /col-lg-8 */}
              <div className="col-lg-4">
                <div className="discover-right">
                  <DiscoverItems
                    cardDiscoverImage={discoverOne}
                    coverIcon={discoverIconOne}
                    cardTitle={cardTitleOne}
                    cardParagraph={paraOne}
                  />
                  <div className="download-brochure d-flex justify-content-center align-items-center">
                    <Link
                      to="/"
                      className="text-decoration-none text-white text-center"
                    >
                      {/* <img src={downloadBrochure} alt='Brochure' title='Brochure' /> */}
                      <span className="text-uppercase text-white w-100 d-flex justify-content-center mt-3">
                        Book Now
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* /col-lg-8 */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurSubBrandsPage;
