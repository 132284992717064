import React from "react";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import MediaPressRelease from "./MediaPressRelease";
import MediaAwards from "./MediaAwards";
import MediaCollaterals from "./MediaCollaterals";
import TabImgOne from "../assets/images/megaphone.svg";
import TabImgTwo from "../assets/images/awards.svg";
import TabImgThree from "../assets/images/collterals.svg";

const MediaTabs = ({ manageMedia ,slugOfMedia}) => {
  const [activeTab, setActiveTab] = React.useState(0);


  const onTabClick = (e, index) => {

    setActiveTab(index);
  };
  // const tabsArray = [...Array(5).keys()];
  const tabsArray = [
    {
      coverId: 0,
      tabCircleImage: TabImgOne,
      name: "PRESS RELEASES",
      component: <MediaPressRelease manageMedia={manageMedia } slugOfMedia={slugOfMedia} />,
    },

    {
      coverId: 1,
      tabCircleImage: TabImgTwo,
      name: "Awards",
      component: <MediaAwards manageMedia={manageMedia} />,
    },

    {
      coverId: 2,
      tabCircleImage: TabImgThree,
      name: "Collaterals",
      component: <MediaCollaterals manageMedia={manageMedia} />,
    },
  ];

  /***************FOLLOW US LINKS  START**************** */

  return (
    <>
      <Tabs activeTab={activeTab} onTabClick={onTabClick} tabsArray={tabsArray}>
        {tabsArray.map((item) => (
          <Tab
            key={item.coverId}
            className={`item ${activeTab === item.coverId
              ? "active text-uppercase d-flex align-items-center"
              : "text-uppercase d-flex align-items-center"
              }`}
          >
            <span className="tab-feature-img d-flex rounded-circle overflow-hidden">
              <img src={item.tabCircleImage} alt={item.name} />
            </span>
            <span className="tab-name">{item.name}</span>
          </Tab>
        ))}
      </Tabs>

      {tabsArray.map((screen) => (
        <TabScreen
          key={screen.coverId}
          activeTab={activeTab}
          index={screen.coverId}
        >
          {activeTab === screen.coverId && screen.component}
        </TabScreen>
      ))}
    </>
  );
};

export default MediaTabs;
