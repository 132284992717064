import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import errorImage from "../assets/images/error-img.svg"
import { IoIosArrowRoundForward } from "react-icons/io";
import { useEffect } from 'react';



function PageNotFound({setErrorPage}) {
   

    useEffect(() => {
        setErrorPage(true);

        // Cleanup function to reset errorPage when component unmounts
        return () => {
            setErrorPage(false);
        };
    }, [setErrorPage]);
    return (
        <>
            <section>
                <div className='container'>
                    <div className='page-not-found'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12'>
                                <div className='error-text'>
                                    <span className='error-small-text'>404 Error</span>
                                    <h2>Page Not Found</h2>
                                    <p>Sorry, the page you are looking for could not be found or has been removed.</p>
                                    <Link to='/' className='text-dark text-decoration-none view-all' title=''>Back to Home <IoIosArrowRoundForward className='fs-4' /></Link>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='error-img mt-lg-0 mt-md-5'>
                                    <img src={errorImage} title='' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
    setErrorPage(false)

   
}

export default PageNotFound
