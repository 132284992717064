import React, { useContext, useState } from 'react'
import { MyContext } from '../Contex/LoadingContex';
import Loader from './Loader';

const BannerImage = ({src="", style={},alt="",title="",className="",isDataIsLoading=false}) => {
    const [loaded,setLoaded] = useState(false)
    const urlParts = src?.split('/');
    const baseURL = urlParts?.slice(0, -2).join('/') + '/';
    const filename = urlParts?.[urlParts?.length - 1];
    const blurredFilename = "thumbnails/blurred-" + filename;
    const blurredURL = baseURL + blurredFilename;

    const [isBannerLoading, setIsBannerLoading] = useState(true);
    const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

    const handleLoad = () => {
        setLoaded(true)
        document.body.classList.remove('slider-open');
        setIsBannerLoading(false);
        setIsLoadingMain(false);
    }
    const handleError = () => {
        setLoaded(true)
        document.body.classList.remove('slider-open');
        setIsBannerLoading(false);
        setIsLoadingMain(false);
      };
      if (isDataIsLoading) {
        setIsLoadingMain(true);
        document.body.classList.add('slider-open');
        return <Loader/>;
      }
      else if (isBannerLoading) {
        document.body.classList.add('slider-open');
        setIsLoadingMain(true);
      }
  return (
    <img className={`${className}`} src={loaded?src:blurredURL} alt={alt} onLoad={handleLoad} onError={handleError} title={title} style={style}/>
  )
}

export default BannerImage