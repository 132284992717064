import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'


import OfferSlider from './OfferSlider'
import { getFeaturedOfferData } from '../services/dataServices'
import { useQuery } from 'react-query'

function SpecialOffers({slugOfOffers}) {


  const gallerySettings = {
    arrow: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "460px",
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          centerPadding: "300px",
        }
      },
      {
        breakpoint: 1399,
        settings: {
          centerPadding: "180px",
        }
      },
      {
        breakpoint: 1199,
        settings: {
          centerPadding: "75px",
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "0",
        }
      }
    ]
  };

  let allFeaturedOffer = useQuery(
    'getFeaturedOffer',
    getFeaturedOfferData
  );



  return (
    <section>
      <div className='special-offers'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='hotel-heading-wrap d-flex align-items-center justify-content-between'>
                <h1 className='text-uppercase georgia heading-space mb-0'>Our Special offers</h1>
                <Link to={`/${slugOfOffers}`}className='text-dark text-decoration-none view-all' title=''>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link>
              </div>
            </div>
          </div>
        </div>
        {/* /container-fluid */}

        <div className='special-offers-slides mt-lg-5 mt-4'>
          <OfferSlider slugOfOffers={slugOfOffers} gallerySettings={gallerySettings} data={allFeaturedOffer} />
        </div>

      </div>
    </section>
  )
}

export default SpecialOffers