import React from 'react';

import awardLeaf from "../../src/assets/images/award-leaf.svg"
function AwardCard({ awardImage, awardLogo, awardCompanyName, awardName, awardee, year }) {

    return (
        <div className={`award-card text-center rounded-3 border`}>
            <div className='award-image position-relative'>
                <img src={awardLeaf} alt='' title='' />
                <div className='award-text position-absolute start-0 top-0 d-flex justify-content-center align-items-center w-100 h-100'>
                    <div className='award-year-title'>
                        {awardName}
                    </div>
                    <div className='award-year position-absolute start-0 bottom-0 end-0 text-center'>
                        {year}
                    </div>
                </div>
            </div>
            <div className='award-by'>
                <div className='award-logo mt-lg-4 mt-3 mb-2'>
                    {awardee}
                </div>
                {/* <span className='d-flex justify-content-center align-items-center m-auto'>{awardCompanyName}</span> */}
            </div>
        </div>
    );
}

export default AwardCard;
