import React from "react";
import { Link } from "react-router-dom";
import SustainFeatureBox from "./SustainFeatureBox";
import { useQuery } from "react-query";
import { getPageData } from "../services/dataServices";
function Sustainability({isPreview, userId,sectionId,slugOfSustainbility=""}) {
  const images = [
    {
      galleryHeading: "Inspired by Nature",
      galleryPara:
        "Lorem Ipsum is simply dummy text of the printing typesetting. Lorem Ipsum is simply dummy text of the printing typesetting.",
    },
  ];

  const pageCode = "HOME";

  const pagesSectionData = useQuery(["pagesSectionData", pageCode,isPreview, userId,sectionId], () =>
    getPageData(pageCode,isPreview, userId, sectionId)
  );


  return (
    <section>
      <div className="sustainability section-padding" >
        <div className="container">
          <div className="sustainability-commitment">
            <div className="row">
              <div className="col-12 text-center text-white">
                {pagesSectionData &&
                  pagesSectionData.data &&
                  pagesSectionData?.data?.data?.map((sectionData, index) => {
                    return (
                      < >
                        <span key={index} className="text-text-uppercase text-white"  >
                          {sectionData &&
                            sectionData.section_details
                              .filter(
                                (item) =>
                                  item.section_code === "OURGRENINITIATIVES"
                              )[0]
                              .section_elements.filter(
                                (item) =>
                                  item.field_label_code === "MAINTITLE"
                              )[0].data}

                        </span>
                        <h2 className="h1 text-uppercase georgia my-lg-4 my-2">
                          {sectionData &&
                            sectionData.section_details
                              .filter(
                                (item) =>
                                  item.section_code === "OURGRENINITIATIVES"
                              )[0]
                              .section_elements.filter(
                                (item) =>
                                  item.field_label_code === "HEADING"
                              )[0].data}

                        </h2>
                        <p className="text-white m-auto" >
                          {sectionData &&
                            sectionData.section_details
                              .filter(
                                (item) =>
                                  item.section_code === "OURGRENINITIATIVES"
                              )[0]
                              .section_elements.filter(
                                (item) =>
                                  item.field_label_code === "CONTENT"
                              )[0].data}

                        </p>
                        <div className="sustain-action mt-5"  >
                          <Link
                            to={`/${slugOfSustainbility}`}
                            className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                          >
                            {sectionData &&
                              sectionData.section_details
                                .filter(
                                  (item) =>
                                    item.section_code === "OURGRENINITIATIVES"
                                )[0]
                                .section_elements.filter(
                                  (item) =>
                                    item.field_label_code === "LEARNMOREBUTTON"
                                )[0].data}
                          </Link>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="commitment-feature text-center">
          <div className="container">
            <div className="row justify-content-center">
              <SustainFeatureBox pagesSectionData={pagesSectionData} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sustainability;
