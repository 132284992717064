import React from "react";

import AtmosphereVideoCover from "../assets/images/atmosphere-living-banner.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";


import Helmet from './Helmet'

function HomeVideo({pagesSectionData1=[]}) {
  


 
  let BannerVideoObject = pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MAINBANNER" && item.field_label_code === "ADDVIDEO")[0];
  let dynamicVideo=BannerVideoObject?.psdd_data;

  
  let newPosterImage = BannerVideoObject?.psdd_data.replace('.mp4','.png');


  const sectionData = pagesSectionData1?.data?.sectionData;
  
  let keywords = sectionData?sectionData[0]?.meta_keyword:"";
  let slug = sectionData?sectionData[0]?.slug:"";
  let meta_title = sectionData?sectionData[0]?.meta_title:"";
  let meta_description = sectionData?sectionData[0]?.meta_description:"";


  return (
    <>
    <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      
      />
      <section>
        <div className="atmosphere-hero-video position-relative">
          <video
            id="BgVideo"
            className="w-100 d-flex"
            poster={AtmosphereVideoCover}
            // poster={newPosterImage}
            preload="auto"
            loop
            muted
            autoPlay={true}
            playsInline
          >
            {dynamicVideo && <source preload="auto" src={dynamicVideo} type="video/mp4" />}
          </video>
          <div className="scroll-down d-flex justify-content-center position-absolute start-0 end-0 z-1 flex-column text-center">
            <a href="#BookNow" className="text-white">
              <span className="arrow1">
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </span>
              <span className="arrow2">
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </span>
              <span className="arrow3">
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeVideo;
