import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import TabResortsView from "./TabResortsView";
import { Link } from "react-router-dom";
import DiscoverLeftItems from "./DiscoverLeftItems.js";
import DiscoverRightItems from "./DiscoverRightItems.js";
import { useState } from "react";
import TabResortSlider from "./TabResortSlider.js";
import resrotOne from '../assets/images/resort-2.jpg'
import Image from "../components/Image.js";

function TabItem({
  index,
  coverImage,
  tabLogo,
  brandSubTitle,
  tabPara = "",
  hotelImage,
  hotelLink,
  hotelName,
  hotelLocation,
  brandtabs,
  tabResorts,
  tabDiscoverLeftSideCards,
  visitResort,
  visitResortName,
  targetVisitResort,
  imgAltTag,
}) {
  // const images = [{
  //     imageUrl:coverOne,imageUrl:tabLogoOne,galleryHeading:'Inspired by Nature', tabPara:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.'}
  // ]

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

 


  const ifsingle = tabResorts?.[0]?.elements?.find(
    (item) => item?.field_title_code === "VISITRESORTBUTTON"
  )?.data;
  return (
    <>
      <div
        className={`tab-top-header position-relative mt-lg-5 mt-4 brandone brand${
          index + 1
        }`}
      >
        <div className="tab-cover rounded-4 overflow-hidden">
        <Image
            className="w-100"
            src={coverImage}
            alt={imgAltTag}
            title=""
          />
          {/* <img
            className="w-100"
            src={coverImage}
            alt={imgAltTag}
            title=""
          /> */}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tab-text-view bg-white position-relative">
                <div className="top-logo d-flex justify-content-center text-center position-relative">
                  <span className="position-relative z-1 d-flex brand-height justify-content-center">
                    <img
                      className="bg-white px-4 w-auto"
                      src={tabLogo}
                      alt=""
                      title=""
                    />
                  </span>
                </div>
                <div className="sub-brand-subtitle mt-xl-5 mt-4 mb-3 text-center">
                  <h4 className="text-uppercase georgia">{brandSubTitle}</h4>
                </div>
                <div className="tabpara text-center m-auto">
                  <p>{tabPara}</p>
                </div>
                {(tabResorts.length !== 1 && tabResorts.length <=3) ? (
                  <div className="resorts-view-all d-flex justify-content-center mt-lg-5 mt-4 pt-xl-2">
                    {tabResorts.map((tabResort, index) => (
                      <TabResortsView
                        key={index}
                        resortImg={
                          tabResort?.elements?.find(
                            (item) => item?.field_title_code === "RESORTIMAGE"
                          )?.data
                        }
                        resortImgAltTag={tabResort?.elements?.find(
                            (item) => item?.field_title_code === "RESORTIMAGE"
                          )?.img_alt_tag}
                        resortName={
                          tabResort?.elements?.find(
                            (item) => item?.field_title_code === "RESORTNAME"
                          )?.data
                        }
                        resortLocation={
                          tabResort?.elements?.find(
                            (item) =>
                              item?.field_title_code === "RESORTLOCATION"
                          )?.data
                        }
                        resortLink={
                          tabResort?.elements?.find(
                            (item) =>
                              item?.field_title_code === "RESORTLOCATION"
                          )?.data
                        }
                        resrotVisitName={tabResort.resrotVisitName}
                        linkTarget={
                          tabResort?.elements?.find(
                            (item) =>
                              item?.field_title_code === "VISITRESORTBUTTON"
                          )?.data
                        }
                      />
                    ))}
                  </div>
                ) : tabResorts.length >3?(<div className="resorts-view-all resort-tab-slides mt-lg-5 mt-4 pt-xl-2">
                {/* {tabResorts.map((tabResort, index) => ( */}
                
                  <TabResortSlider resortSlideData={tabResorts}/>
                {/* ))}  */}
              </div>):(
                  <div className="hotel-view-tab ms-auto me-auto mt-lg-5 mt-4">
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="left-thumb-img">
                        <Image
                          src={
                            tabResorts[0]?.elements?.find(
                              (item) => item?.field_title_code === "RESORTIMAGE"
                            )?.data
                          }
                          alt={tabResorts[0]?.elements?.find(
                              (item) => item?.field_title_code === "RESORTIMAGE"
                            )?.img_alt_tag}
                          title=""
                        />
                        {/* <img
                          src={
                            tabResorts[0]?.elements?.find(
                              (item) => item?.field_title_code === "RESORTIMAGE"
                            )?.data
                          }
                          alt={tabResorts[0]?.elements?.find(
                              (item) => item?.field_title_code === "RESORTIMAGE"
                            )?.img_alt_tag}
                          title=""
                        /> */}
                      </div>
                      <div className="right-hotel-text">
                        <h4 className="georgia">
                          {
                            tabResorts[0]?.elements?.find(
                              (item) => item?.field_title_code === "RESORTNAME"
                            )?.data
                          }
                        </h4>
                        <p>
                          {
                            tabResorts[0]?.elements?.find(
                              (item) =>
                                item?.field_title_code === "RESORTLOCATION"
                            )?.data
                          }
                        </p>
                        <div className="visit-now">
                        {ifsingle ?
                          <Link
                            to={
                              tabResorts[0].elements.find(
                                (item) =>
                                  item?.field_title_code === "VISITRESORTBUTTON"
                              )?.data
                            }
                            target="_blank"
                            className="text-uppercase"
                          >
                            {"VISIT RESORT"}
                            <FontAwesomeIcon
                              className="ms-2"
                              icon={faAngleRight}
                            />
                          </Link>:   <Link className="text-uppercase"
                          >
                          {"COMING SOON"}
                          <FontAwesomeIcon
                              className="ms-2"
                              icon={faAngleRight}
                            />
                            </Link>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="atmosphere-discover section-padding pb-0">
                <div className="row">
                  <div className="col-12">
                    <div className="dashed-heading position-relative">
                      <h2 className="georgia text-uppercase">
                        <span>Discover</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="discover-left">
                    <div className="row gx-0 mt-lg-5 mt-4">
                      {tabDiscoverLeftSideCards?.map((tabDiscover, index) => (
                        <DiscoverLeftItems
                          key={index}
                          discoverImage={
                            tabDiscover?.elements?.find(
                              (item) =>
                                item?.field_title_code === "DISCOVERIMAGE"
                            )?.data
                          }
                          discoverImageAltTag={
                            tabDiscover?.elements?.find(
                              (item) =>
                                item?.field_title_code === "DISCOVERIMAGE"
                            )?.img_alt_tag
                          }
                          discoverTitle={
                            tabDiscover?.elements?.find(
                              (item) =>
                                item?.field_title_code === "DISCOVERTITLE"
                            )?.data
                          }
                          discoverIcon={
                            tabDiscover?.elements?.find(
                              (item) =>
                                item?.field_title_code === "DISCOVERICON"
                            )?.data
                          }
                          discoverDescription={
                            tabDiscover?.elements?.find(
                              (item) => item?.field_title_code === "DESCRIPTION"
                            )?.data
                          }
                        />
                      ))}
                    </div>

                  <div className="col-lg-4">
                    {/* <div className="discover-right">
                      {tabDiscoverRightSideCards?.map((tabDiscover, index) => (
                        <DiscoverRightItems
                          key={index}
                          discoverImage={
                            tabDiscoverRightSideCards[0].elements?.find(
                              (item) =>
                                item.field_title_code === "DISCOVERIMAGE"
                            )?.data
                          }
                          discoverImageAltTag={
                            tabDiscoverRightSideCards[0].elements?.find(
                              (item) =>
                                item.field_title_code === "DISCOVERIMAGE"
                            )?.img_alt_tag
                          }
                          discoverTitle={
                            tabDiscoverRightSideCards[0].elements?.find(
                              (item) =>
                                item?.field_title_code === "DISCOVERTITLE"
                            )?.data
                          }
                          discoverIcon={
                            tabDiscoverRightSideCards[0].elements?.find(
                              (item) =>
                                item?.field_title_code === "DISCOVERICON"
                            )?.data
                          }
                          discoverDescription={
                            tabDiscoverRightSideCards[0].elements?.find(
                              (item) => item?.field_title_code === "DESCRIPTION"
                            )?.data
                          }
                        />
                      ))}
                      <div className="download-brochure d-flex">
                        <div
                          // key={downloadLink.id}
                          className="w-100 h-100 d-flex"
                        >
                          <Link
                            className="text-decoration-none text-white text-center w-100 d-flex align-items-center justify-content-center"
                            onClick={handleShow}
                            target={isDiscover5LinkActive ? "_blank" : "_self"}
                            to={
                              isDiscover5LinkActive
                                ? linksData[0].elements.filter(
                                    (item) =>
                                      item?.field_title_code ===
                                      "DISCOVERBOOKNOW"
                                  )[0].data
                                : "/contact-us"
                            }
                          >
                            <span className="text-uppercase text-white w-100 d-flex justify-content-center">
                              {isDiscover5LinkActive ? "BOOK NOW" : "ENQUIRE"}
                            </span>
                          </Link>
                         
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabItem;
