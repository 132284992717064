import React,{useContext,useState} from 'react'
import { Breadcrumb } from 'react-bootstrap'
import innerCover from '../../assets/images/privacy-back-cover.jpg'
import { Link } from 'react-router-dom'
import PrivacyList from '../../components/PrivacyList'
import { useQuery } from 'react-query'
import { getPageData,getPageData1 } from '../../services/dataServices'
import parse from "html-react-parser";
import Helmet from '../../components/Helmet'
import { MyContext } from "../../Contex/LoadingContex"
import Loader from '../../components/Loader'
import BannerImage from '../../components/BannerImage'
function PrivacyPolicy({ isPreview, userId, sectionId }) {
  let pageCode = "PRIVACYPOLICY"
  
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
     
  const {isLoading,isSuccess}=pagesSectionData1
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

  const privacyImage=pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEPP" && item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data ?? "";


  const pagesSectionData = useQuery(["pagesSectionData", pageCode, isPreview, userId, sectionId], () =>
    getPageData(pageCode, isPreview, userId, sectionId)
  );

  // const privacyData = pagesSectionData?.data?.data?.map((sectionData) => sectionData?.section_details?.filter((item) => item.section_code === "MANAGEPP")[0] ?? "")
//   const privacyImage = privacyData?.[0]?.section_elements.filter(
//     (item) => item.field_label_code === "BANNERIMAGE"
// )[0]?.data ;
  const privacyImageAltTag =pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MANAGEPP" && item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? ""
  //  privacyData?.[0]?.section_elements.filter(
  //   (item) => item.field_label_code === "BANNERIMAGE"
  // )[0]?.img_alt_tag ;

  const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = pagesSectionData?.data?.data[0]?.slug;
  const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  const privacyContent = pagesSectionData1?.data?.sectionData?.filter((item) => item.field_label_code === "PRIVACYPOLICY"
    )[0]?.psdd_data ?? ""
    // privacyData?.[0]?.section_elements.filter(
    //   (item) => item.field_label_code === "PRIVACYPOLICY"
    // )[0]?.data ?? "";
    // 
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img className="w-100 h-100" src={privacyImage} 
              onLoad={handleImageLoad}
              onError={handleError}            
             title="" 
             alt={privacyImageAltTag} /> */}
              <BannerImage
              className="w-100 h-100"
              src={privacyImage}
              title=""
              alt={privacyImageAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Privacy Policy
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <sectio>
        <div className="privacy-policy-wrapper section-padding">
          <div className="container">
            <div className="conditions-inner-wrapper">
              {parse(`${privacyContent}`)}
              
            </div>
          </div>
        </div>
      </sectio>
    </>
  )
}

export default PrivacyPolicy
