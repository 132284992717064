import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import bottomStroke from '../assets/images/brush-stoke.png'
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Image from "./Image";
function HotelsResortsMobileSlider({ images, gallerySettings }) {
  const settings = { ...gallerySettings };
  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className="hotel-resort-mobile-slide position-relative"
          >
            <div className="mob-slide-image position-relative">
            <Image
                className="w-100"
                src={image.imageUrl}
                alt="Our Gallery"
                title="Our Gallery"
              />
              {/* <img
                className="w-100"
                src={image.imageUrl}
                alt="Our Gallery"
                title="Our Gallery"
              /> */}
            </div>
            <div className="mob-slide-caption text-center position-absolute start-0 end-0 top-0 bottom-0 d-flex align-items-center justify-content-center z-1">
              <motion.div
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="caption-wrap text-center text-white"
              >
                <h1 className="text-uppercase georgia">{image.title}</h1>
                <span className="text-uppercase">{image.heading}</span>
                <div className="bottom-stroke my-4 d-flex justify-content-center">
                  <img src={bottomStroke} alt="" title="" />
                </div>
                <small className="text-uppercase">{image.desc}</small>
                {/* <Link className='text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center' to={image.visiteResort}>
                                    {image.visitBtnText}
                                </Link> */}
                <div className="slide-action d-flex justify-content-center">
                  {image.link ? (
                    <Link
                      target={image.linkTarget}
                      to={image.link ? image.link : ""}
                      className="text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center"
                    >
                      {image.link ? image.linkName : "COMING SOON"}{" "}
                      <FontAwesomeIcon
                        icon={faArrowRightLong}
                        className="ms-3 icon-font"
                      />
                    </Link>
                  ) : (
                    <span className="text-white text-decoration-none view-all mt-xxl-5 mt-4 d-flex justify-content-center align-items-center">
                      COMING SOON{" "}
                      <FontAwesomeIcon
                        icon={faArrowRightLong}
                        className="ms-3 icon-font"
                      />
                    </span>
                  )}
                </div>
              </motion.div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default HotelsResortsMobileSlider;
