import "./App.css";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import { Home } from "./pages/home/Home";
import AllRoutes from "./allRoutes/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Popup from "./components/Popup";
import { useState,useContext } from "react";
import { MyContext } from "../src/Contex/LoadingContex";
import { setupAxiosInterceptor } from "./services/dataServices";
import UnderMaintenance from "./components/UnderMaintainencePage";

function App() {
  const [errorPage, setErrorPage] = useState(false);
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  const [isServerDown,setIsServerDown]=useState(false);
  setupAxiosInterceptor(setIsServerDown);
  return (
    <>
       {/* {isServerDown === true ?<UnderMaintenance/>:''}
    { errorPage === true ? (
        <Header pageNoteFoundBackground={"page-note-found-header"} />
      ) : isLoadingMain ? (
       <Loader/>
      ) : (
        <Header  />
      )}
    <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />
   
    {errorPage === true ? (<Footer />):isLoadingMain ? <></> : <Footer />}
    <ToastContainer /> */}
    {isServerDown === true ? (
  <UnderMaintenance setIsServerDown={setIsServerDown} />
) : (
  <>
    {/* Header based on errorPage or loading status */}
    {errorPage === true ? (
      <Header pageNoteFoundBackground={"page-note-found-header"} />
    ) : isLoadingMain ? (
      <Loader />
    ) : (
      <Header />
    )}

    {/* Routes */}
    <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />

    {/* Footer based on errorPage or loading status */}
    {errorPage === true ? (
      <Footer />
    ) : isLoadingMain ? <></> : (
      <Footer />
    )}
  </>
)}


<ToastContainer />


      
    </>
  );
}

export default App;
