import React, { useState } from 'react'

const Image = ({src="", style={},alt="",title="",className=""}) => {
    const [loaded,setLoaded] = useState(false)
    const urlParts = src?.split('/');
    const baseURL = urlParts?.slice(0, -2).join('/') + '/';
    const filename = urlParts?.[urlParts?.length - 1];
    const blurredFilename = "thumbnails/blurred-" + filename;
    const blurredURL = baseURL + blurredFilename;
    const handleLoad = () => {
        setLoaded(true)
    }
  return (
    <img className={`${className}`}  src={loaded?src:blurredURL} alt={alt} onLoad={handleLoad} onError={()=>setLoaded(true)} title={title} style={style}/>
  )
}

export default Image