import React from 'react'
import parse from 'html-react-parser'
import Image from '../components/Image'

function DiscoverItems({cardDiscoverImage,coverIcon, cardTitle, cardParagraph}) {
  return (
    <>
        <div className='discover-card'>
            <div className='discover-card-image'>
            <img className='w-100' src={cardDiscoverImage} alt='Discover' title='Discover' />
            {/* <img className='w-100' src={cardDiscoverImage} alt='Discover' title='Discover' /> */}
            </div>
            <div className='discover-card-body position-relative d-flex align-items-center justify-content-center flex-column'>
                <div className='discover-feature-icon rounded-circle d-flex align-items-center justify-content-center'>
                    <img src={coverIcon} alt='Feature' title='Feature' />
                </div>
                <div className='discover-title my-lg-3 mt-lg-4 my-2 georgia'>
                    {cardTitle}
                </div>
                <div className='discover-text text-center'>
                    <p>{parse(cardParagraph)}</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default DiscoverItems