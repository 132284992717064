import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";
import { getWebMenu, setupAxiosInterceptor } from '../services/dataServices';
import logoImage from "../assets/images/logo.svg"
import phoneImage from "../assets/images/phone.svg"
import emailImage from "../assets/images/email.svg"

function UnderMaintenance({ setIsServerDown }) {
  const navigate = useNavigate();

  useEffect(() => {
    setupAxiosInterceptor(setIsServerDown);
    
    const intervalId = setInterval(async () => {
      const data = await getWebMenu('maintainence');
      if (data) {
        // Redirect to the home page upon successful data retrieval
        navigate('/');
        clearInterval(intervalId); // Clear the interval to stop polling
      }
    }, 5000); // Poll every 5 seconds

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [setIsServerDown, navigate]);

  return (
    <>
     <section>
            <div class="getting-ready">
                <div class="getting-ready-inner">
                    <div class="project-logo">
                        <img src={logoImage} alt=""/>
                    </div>
                    <div class="getting-ready-content">
                        <h1>getting Ready!</h1>
                        <p>We’re catching a wave of updates, but you don’t have to wait to book your next tropical beachside stay!</p>
                        <a href="https://search.atmospherehotelsandresorts.com/AHR/AtmosphereHotelsandResorts#/" target="_blank" class="book-now-btn">book now</a>
                    </div>
                    <div class="footer-info ahr">
                        <a href="tel:+960 3300 668"><span><img src={phoneImage} alt=""/></span> +960 3300 668</a>
                        <a href="mailto:sales@atmospherehotelsandresorts.com"><span><img src={emailImage} alt=""/></span> sales@atmospherehotelsandresorts.com</a>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default UnderMaintenance;
